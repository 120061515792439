// Large Headlines Generator
// ASCII Art Blocks
// http://patorjk.com/software/taag/#p=display&f=Graffiti&t=Page%20Section
// 🔖Vz.:
// 1🟥 basic imports and state
// 2🟥 useEffect and handleSubmit
// 3🟥 form calculation
// 4🟥 returned html
//🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
//_               _        _                            _                         _       _        _
//| |             (_)      (_)                          | |                       | |     | |      | |
//| |__   __ _ ___ _  ___   _ _ __ ___  _ __   ___  _ __| |_ ___    __ _ _ __   __| |  ___| |_ __ _| |_ ___
//| '_ \ / _` / __| |/ __| | | '_ ` _ \| '_ \ / _ \| '__| __/ __|  / _` | '_ \ / _` | / __| __/ _` | __/ _ \
//| |_) | (_| \__ \ | (__  | | | | | | | |_) | (_) | |  | |_\__ \ | (_| | | | | (_| | \__ \ || (_| | ||  __/
//|_.__/ \__,_|___/_|\___| |_|_| |_| |_| .__/ \___/|_|   \__|___/  \__,_|_| |_|\__,_| |___/\__\__,_|\__\___|
//  🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
import "./nk_monatsprotokoll.css";
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";

import { useFirestore } from "../../../hooks/useFirestore";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDocument } from "../../../hooks/useDocument";

const NKallgemein = () => {
  //🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦
  // Update-Vorkehrungen-Start
  let { calculationId } = useParams();
  if (calculationId) {
    calculationId = calculationId.slice(1);
    // alert(`"errechnet:" ${calculationId}`);
  }
  // Update-Vorkehrungen-Ende
  //⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️

  const safeCalculationId = useRef(calculationId).current;
  const history = useHistory();
  const { user } = useAuthContext();
  const [storedRows, setStoredRows] = useState([]);
  const [speicherTitel, setSpeicherTitel] = useState("");
  const { addDocument: addNewDocument, response: useFirestoreResponse } =
    useFirestore("calculations");
  //🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦
  // Update-Vorkehrungen-Start
  const { document: loadedDocument, error } = useDocument(
    "calculations",
    safeCalculationId
  );
  // Update-Vorkehrungen-Ende
  //⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️

  console.log(speicherTitel);

  //🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦
  // Update-Vorkehrungen
  const [update, setUpdate] = useState(false);
  const { updateDocument, response: updateResponse } =
    useFirestore("calculations");
  // Update-Vorkehrungen-Ende
  //⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️
  const [inputValues, setInputValues] = useState({});

  const [users, setUsers] = useState([]);
  const [anmerkungen, setAnmerkungen] = useState("");
  const [category, setCategory] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  // const [filteredUsers, setFilteredUsers] = useState([]);
  const [formError, setFormError] = useState(null);
  const categories = [
    { value: "allgemein", label: "allgemein" },
    { value: "Lohnvg.", label: "Lohnvg." },
    { value: "Beiträge", label: "Beiträge" },
    { value: "Steuern", label: "Steuern" },
  ];

  const { documents } = useCollection("users");
  // State variables to track input values and sums

  const [sums, setSums] = useState({
    sum2: 0,
    sum3: 0,
    sum4: 0,
  });

  //🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  //                _____ __  __                   _   _                     _ _      _____       _
  //               |  ___/ _|/ _|                 | | | |                   | | |    /  ___|     | |
  // _   _ ___  ___| |__| |_| |_    __ _ _ __   __| | | |__   __ _ _ __   __| | | ___\ `--. _   _| |__
  //| | | / __|/ _ \  __|  _|  _|  / _` | '_ \ / _` | | '_ \ / _` | '_ \ / _` | |/ _ \`--. \ | | | '_ \
  //| |_| \__ \  __/ |__| | | |_  | (_| | | | | (_| | | | | | (_| | | | | (_| | |  __/\__/ / |_| | |_) |
  // \__,_|___/\___\____/_| |_(_)  \__,_|_| |_|\__,_| |_| |_|\__,_|_| |_|\__,_|_|\___\____/ \__,_|_.__(_)

  //🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  // Update input values and calculate sums
  const handleInputChange = (e) => {
    const inputId = e.target.id;
    const inputValue = e.target.value;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: inputValue,
    }));
  };

  useEffect(() => {
    if (documents) {
      setUsers(
        documents.map((user) => ({
          value: { displayName: user.displayName, id: user.id },
          label: user.displayName,
        }))
      );
    }
  }, [documents]);
  //🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦🟦
  // Update-Vorkehrungen-Start

  useEffect(() => {
    if (users && safeCalculationId && loadedDocument && !update) {
      console.log(loadedDocument);
      // alert("is NO update");

      setSpeicherTitel(loadedDocument.speicherTitel);
      setAnmerkungen(loadedDocument.anmerkungen);
      setCategory(loadedDocument.category);
      setAssignedUsers(loadedDocument.assignedUsersList);
      setInputValues(loadedDocument.inputValues);

      // setAssignedUsers(
      //   users.filter((user) => {
      //     // Check if the user's id is present in assignedUsers array
      //     return loadedDocument.assignedUsersList.some(
      //       (assignedUser) => assignedUser.id === user.value.id
      //     );
      //   })
      // );
    }
  }, [loadedDocument, safeCalculationId, update]);
  // Update-Vorkehrungen-Ende

  //⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️⬛️

  const handleSubmit = async (e, isUpdate) => {
    e.preventDefault();

    let addedDocumentId = "";

    if (!category) {
      setFormError("Bitte eine Kategorie wählen.");
      return;
    }

    if (assignedUsers.length < 1) {
      setFormError("Bitte mindestens 1 Teilnehmer zuweisen.");
      return;
    }
    const assignedUsersList = assignedUsers;
    // const assignedUsersList = assignedUsers.map((u) => ({
    //   displayName: u.value.displayName,
    //   id: u.value.id,
    // }));

    const createdBy = {
      displayName: user.displayName,
      id: user.uid,
    };

    const rows = [];

    for (let i = 1; i <= 31; i++) {
      const id = i.toString().padStart(2, "0");

      const row = {
        mp1: inputValues[`mp${id}_1`] || "",
        mp2: inputValues[`mp${id}_2`] || "",
        mp3: inputValues[`mp${id}_3`] || "",
        mp4: inputValues[`mp${id}_4`] || "",
        mp5: inputValues[`mp${id}_5`] || "",
      };

      rows.push(row);
    }

    setStoredRows(rows);
    const calcSummary = {
      a01_Vorlage: "Monatsprotokoll",
      a02_Summe_Wert_1: sums.sum2,
      a03_Summe_Wert_2: sums.sum3,
      a04_Summe_Wert_3: sums.sum4,
    };
    const calculation = {
      speicherTitel: speicherTitel,
      bearbeitungsPfad: "/neue_kalkulation/nk_monatsprotokoll",
      anmerkungen: anmerkungen,
      assignedUsersList: assignedUsersList,
      createdBy: createdBy,
      category: category,
      calcData: calcSummary,
      inputValues: inputValues,
      comments: [],
    };
    if (isUpdate) {
      // alert(calculation.calcData[0].a01_Vorlage);
      // alert("is update");

      try {
        await updateDocument(safeCalculationId, {
          speicherTitel: speicherTitel,
          bearbeitungsPfad: "/neue_kalkulation/nk_monatsprotokoll",
          anmerkungen: anmerkungen,
          assignedUsersList: assignedUsersList,
          createdBy: createdBy,
          category: category,
          calcData: calcSummary,
          inputValues: inputValues,
          comments: [],
        });

        // Handle successful update
      } catch (error) {
        setFormError("update fehlgeschlagen");
      }
    } else {
      addedDocumentId = await addNewDocument(calculation);
    }
    if (isUpdate && !updateResponse.error) {
      console.log(updateResponse);
      history.push(`/calculations/${calculationId}`);
    }
    if (!isUpdate && !useFirestoreResponse.error) {
      console.log(useFirestoreResponse);
      history.push(`/calculations/${addedDocumentId}`);
    }
  };
  // 🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  //   __                                 _            _       _   _
  //  / _|                               | |          | |     | | (_)
  // | |_ ___  _ __ _ __ ___     ___ __ _| | ___ _   _| | __ _| |_ _  ___  _ __
  // |  _/ _ \| '__| '_ ` _ \   / __/ _` | |/ __| | | | |/ _` | __| |/ _ \| '_ \
  // | || (_) | |  | | | | | | | (_| (_| | | (__| |_| | | (_| | |_| | (_) | | | |
  // |_| \___/|_|  |_| |_| |_|  \___\__,_|_|\___|\__,_|_|\__,_|\__|_|\___/|_| |_|

  //  🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  useEffect(() => {
    const calculateSums = () => {
      let sum2 = 0;
      let sum3 = 0;
      let sum4 = 0;

      for (let i = 1; i <= 31; i++) {
        const id = i.toString().padStart(2, "0");
        const value2 = parseFloat(inputValues[`mp${id}_2`]) || 0;
        const value3 = parseFloat(inputValues[`mp${id}_3`]) || 0;
        const value4 = parseFloat(inputValues[`mp${id}_4`]) || 0;

        sum2 += value2;
        sum3 += value3;
        sum4 += value4;
      }

      setSums({
        sum2: sum2,
        sum3: sum3,
        sum4: sum4,
      });
    };

    calculateSums();
  }, [inputValues]);
  //🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  //          _                             _   _     _             _
  //         | |                           | | | |   | |           | |
  // _ __ ___| |_ _   _ _ __ _ __   ___  __| | | |__ | |_ _ __ ___ | |
  //| '__/ _ \ __| | | | '__| '_ \ / _ \/ _` | | '_ \| __| '_ ` _ \| |
  //| | |  __/ |_| |_| | |  | | | |  __/ (_| | | | | | |_| | | | | | |
  //|_|  \___|\__|\__,_|_|  |_| |_|\___|\__,_| |_| |_|\__|_| |_| |_|_|

  //🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  return (
    <div>
      <h2>Monatsprotokoll</h2>
      <div className="monatsptk_container">
        <div className="monatsptk_header">Titel:</div>
        <div className="monatsptk_row monatsptk_header">
          <div className="monatsptk_titel">
            <input
              value={speicherTitel}
              onChange={(e) => setSpeicherTitel(e.target.value)}
              id="mp00_1"
              type="text"
            />
          </div>
        </div>
        <div className="monatsptk_header">Anmerkungen:</div>
        <div className="monatsptk_row monatsptk_header">
          <div className="monatsptk_titel">
            <textarea
              value={anmerkungen}
              onChange={(e) => setAnmerkungen(e.target.value)}
              id="mp00_2"
              type="text"
            ></textarea>
          </div>
        </div>
        <div className="monatsptk_row monatsptk_header">
          <div className="monatsptk_date">Datum</div>
          <div className="monatsptk_werte">Spalte 1</div>
          <div className="monatsptk_werte">Spalte 2</div>
          <div className="monatsptk_werte">Spalte 3</div>
          <div className="monatsptk_note">Notizen</div>
        </div>
      </div>
      <div className="monatsptk_row  ">
        <div className="monatsptk_date"> </div>
        <div className="monatsptk_werte">∑ {sums.sum2}</div>
        <div className="monatsptk_werte">∑ {sums.sum3}</div>
        <div className="monatsptk_werte">∑ {sums.sum4}</div>
        <div className="monatsptk_note"> </div>
      </div>
      {Array.from({ length: 31 }, (_, index) => {
        const number = index + 1;
        const id = number.toString().padStart(2, "0");

        return (
          <div className="monatsptk_row" key={`row_${id}`}>
            <div className="monatsptk_date">
              <input
                id={`mp${id}_1`}
                type="text"
                onChange={handleInputChange}
                value={inputValues[`mp${id}_1`] || ""}
              />
            </div>
            <div className="monatsptk_werte">
              <input
                id={`mp${id}_2`}
                type="text"
                onChange={handleInputChange}
                value={inputValues[`mp${id}_2`] || ""}
              />
            </div>
            <div className="monatsptk_werte">
              <input
                id={`mp${id}_3`}
                type="text"
                onChange={handleInputChange}
                value={inputValues[`mp${id}_3`] || ""}
              />
            </div>
            <div className="monatsptk_werte">
              <input
                id={`mp${id}_4`}
                type="text"
                onChange={handleInputChange}
                value={inputValues[`mp${id}_4`] || ""}
              />
            </div>
            <div className="monatsptk_note">
              <textarea
                id={`mp${id}_5`}
                rows="1"
                onChange={handleInputChange}
                value={inputValues[`mp${id}_5`] || ""}
              ></textarea>
            </div>
          </div>
        );
      })}
      <br />
      <br />
      <label className="bruttoNettoAnmkg speicherTitel">
        <span>Kategorie:</span>
        <Select
          onChange={(option) => setCategory(option)}
          options={categories}
          placeholder="bitte auswählen"
          value={category}
        />
      </label>
      <label className="bruttoNettoAnmkg speicherTitel">
        <span>gespeichert für:</span>
        <Select
          // placeholder="bitte sich auch selbst hinzufügen..."
          isMulti
          value={assignedUsers}
          onChange={(option) => setAssignedUsers(option)}
          options={users}
        />
      </label>
      <span>{safeCalculationId}</span>
      {safeCalculationId ? (
        <button
          onClick={(e) => handleSubmit(e, true)}
          className="btn kalk_speichern"
        >
          Formular updaten
        </button>
      ) : (
        <button
          onClick={(e) => handleSubmit(e, false)}
          className="btn kalk_speichern"
        >
          Formular speichern
        </button>
      )}
      {formError && <p className="error">{formError}</p>}
      {storedRows.length > 0 && (
        <div>
          <h3>Stored Rows</h3>
          <div className="monatsptk_row monatsptk_header">
            <div className="monatsptk_date">Datum</div>
            <div className="monatsptk_werte">Spalte 1</div>
            <div className="monatsptk_werte">Spalte 2</div>
            <div className="monatsptk_werte">Spalte 3</div>
            <div className="monatsptk_note">Notizen</div>
          </div>
          {storedRows.map((row, index) => (
            <div className="monatsptk_row" key={`stored_row_${index}`}>
              <div className="monatsptk_date">{row.mp1}</div>
              <div className="monatsptk_werte">{row.mp2}</div>
              <div className="monatsptk_werte">{row.mp3}</div>
              <div className="monatsptk_werte">{row.mp4}</div>
              <div className="monatsptk_note">{row.mp5}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NKallgemein;
