import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCOuVrlcAkUqH4rF8c23vKelQYWSFMe71g",
  authDomain: "the-dojo-28f34.firebaseapp.com",
  projectId: "the-dojo-28f34",
  storageBucket: "the-dojo-28f34.appspot.com",
  messagingSenderId: "1032066904960",
  appId: "1:1032066904960:web:6bae5b8b0ba566d93a4765"
};


// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp, projectStorage }