import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

// styles & images
import "./Navbar.css";
import Temple from "../assets/temple.png";
import avatar_holy from "../assets/Avatar_Holy.png";

export default function Navbar() {
  const { logout, isPending } = useLogout();
  const { user } = useAuthContext();

  return (
    <section class="top-nav">
      <div className="logoContainer">
        <img src={Temple} alt="dojo logo" />

        <span className="logoText">Wolkenforum</span>
      </div>
      <input type="checkbox" id="menu-toggle" />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu">
        {!user && (
          <>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/signup">Anmeldung</Link>
            </li>
          </>
        )}

        {user && (
          <>
            <li className="sponsor">
              <span>Abo-Credits: gesponsert von T.C.</span>
            </li>
            <li>
              {!isPending && (
                <button className="btn" onClick={logout}>
                  Logout
                </button>
              )}
              {isPending && (
                <button className="btn" disabled>
                  Logging out...
                </button>
              )}
            </li>
          </>
        )}
      </ul>
    </section>
  );
}
