import { useState } from "react";
import React from "react";
import "./bruttoNettoR.css";

import BruttoNettoContext from "./BruttoNettoContext";
import BnrLogo from "../../../../assets/BnrLogo.svg";

import BruttoNettoRSetup from "../b_setup/BruttoNettoRSetup.js";

const initialcontainerState = {
  ergJaHoNetto: 0,
  ergJaHoBrutto: 0,
  ergDGZkd: 0,
  extDGZkdr: 0,
  ergDGZkdNetto: 0,
  ergDGSV: 0,
  ergDGMV: 0,
  ergDGgesamt: 0,
  szEStFreiBtg: 620,
  sz14SVsgSatz: 0,
  sz14SVsgBeitrag: 0,
  steuerSZ14: 0,
  nettoSZ14: 0,
  sz13SVsgSatz: 0,
  sz13SVsgBeitrag: 0,
  steuerSZ13: 0,
  nettoSZ13: 0,
  createdBy: "",
  documents: "",
  loadedDocument: "",
  bruttoGehalt: 333.74,
  abzugsBetrag: 11,
  steuerAbzug: 35.08,
  sVsgBeitrag: 0,
  sVsgSatz: 0,
  steuer: 0,
  nettoGehalt: 0,
  anmerkungen: "",
  speicherTitel: "",
  category: "",
  assignedUsers: "",
  calculationId: "",
  user: "",
  formError: "",
  users: "",
  update: false,
  categories: [
    { value: "Lohnvg.", label: "Lohnvg." },
    { value: "Beiträge", label: "Beiträge" },
    { value: "Steuern", label: "Steuern" },
    { value: "Sonstiges", label: "Sonstiges" },
  ],
};

export default function BruttoNettoRContainer() {
  const [containerState, setContainerState] = useState(initialcontainerState);

  function handleContainerState(handleType, value) {
    if (handleType === "szEStFreiBtg") {
      setContainerState((prevState) => ({
        ...prevState,
        szEStFreiBtg: parseFloat(value),
      }));
    }
    if (handleType === "ergJaHoBrutto") {
      setContainerState((prevState) => ({
        ...prevState,
        ergJaHoBrutto: parseFloat(value),
      }));
    }

    if (handleType === "ergJaHoNetto") {
      setContainerState((prevState) => ({
        ...prevState,
        ergJaHoNetto: parseFloat(value),
      }));
    }
    if (handleType === "ergDGgesamt") {
      setContainerState((prevState) => ({
        ...prevState,
        ergDGgesamt: parseFloat(value),
      }));
    }
    if (handleType === "ergDGMV") {
      setContainerState((prevState) => ({
        ...prevState,
        ergDGMV: parseFloat(value),
      }));
    }
    if (handleType === "ergDGSV") {
      setContainerState((prevState) => ({
        ...prevState,
        ergDGSV: parseFloat(value),
      }));
    }
    if (handleType === "ergDGZkd") {
      setContainerState((prevState) => ({
        ...prevState,
        ergDGZkd: parseFloat(value),
      }));
    }
    if (handleType === "ergDGZkdNetto") {
      setContainerState((prevState) => ({
        ...prevState,
        ergDGZkdNetto: parseFloat(value),
      }));
    }
    if (handleType === "ergDGZkdr") {
      setContainerState((prevState) => ({
        ...prevState,
        ergDGZkdr: parseFloat(value),
      }));
    }

    if (handleType === "nettoSZ13") {
      setContainerState((prevState) => ({
        ...prevState,
        nettoSZ13: parseFloat(value),
      }));
    }
    if (handleType === "steuerSZ13") {
      setContainerState((prevState) => ({
        ...prevState,
        steuerSZ13: parseFloat(value),
      }));
    }
    if (handleType === "sz13SVsgSatz") {
      setContainerState((prevState) => ({
        ...prevState,
        sz13SVsgSatz: parseFloat(value),
      }));
    }
    if (handleType === "sz13SVsgBeitrag") {
      setContainerState((prevState) => ({
        ...prevState,
        sz13SVsgBeitrag: parseFloat(value),
      }));
    }

    if (handleType === "nettoSZ14") {
      setContainerState((prevState) => ({
        ...prevState,
        nettoSZ14: parseFloat(value),
      }));
    }
    if (handleType === "steuerSZ14") {
      setContainerState((prevState) => ({
        ...prevState,
        steuerSZ14: parseFloat(value),
      }));
    }
    if (handleType === "sz14SVsgSatz") {
      setContainerState((prevState) => ({
        ...prevState,
        sz14SVsgSatz: parseFloat(value),
      }));
    }
    if (handleType === "sz14SVsgBeitrag") {
      setContainerState((prevState) => ({
        ...prevState,
        sz14SVsgBeitrag: parseFloat(value),
      }));
    }

    if (handleType === "formError") {
      setContainerState((prevState) => ({
        ...prevState,
        formError: value,
      }));
    }

    if (handleType === "documents") {
      setContainerState((prevState) => ({
        ...prevState,
        documents: value,
      }));
    }
    if (handleType === "users") {
      setContainerState((prevState) => ({
        ...prevState,
        users: value,
      }));
    }
    if (handleType === "user") {
      setContainerState((prevState) => ({
        ...prevState,
        user: value,
      }));
    }

    if (handleType === "update") {
      setContainerState((prevState) => ({
        ...prevState,
        update: value,
      }));
    }
    if (handleType === "loadedDocument") {
      setContainerState((prevState) => ({
        ...prevState,
        loadedDocument: value,
      }));
    }
    if (handleType === "bruttoGehalt") {
      console.log("state update: bruttoGehalt");

      setContainerState((prevState) => ({
        ...prevState,
        bruttoGehalt: value,
      }));
    }
    if (handleType === "calculationId") {
      setContainerState((prevState) => ({
        ...prevState,
        calculationId: value,
      }));
    }

    if (handleType === "abzugsBetrag") {
      setContainerState((prevState) => ({
        ...prevState,
        abzugsBetrag: value,
      }));
    }
    if (handleType === "steuerAbzug") {
      setContainerState((prevState) => ({
        ...prevState,
        steuerAbzug: value,
      }));
    }
    if (handleType === "sVsgBeitrag") {
      console.log("sVsgBeitrag", value);
      setContainerState((prevState) => ({
        ...prevState,
        sVsgBeitrag: value,
      }));
    }
    if (handleType === "sVsgSatz") {
      setContainerState((prevState) => ({
        ...prevState,
        sVsgSatz: value,
      }));
    }
    if (handleType === "steuer") {
      console.log("handleType steuer", value);
      setContainerState((prevState) => ({
        ...prevState,
        steuer: value,
      }));
    }
    if (handleType === "nettoGehalt") {
      setContainerState((prevState) => ({
        ...prevState,
        nettoGehalt: value,
      }));
    }

    if (handleType === "anmerkungen") {
      setContainerState((prevState) => ({
        ...prevState,
        anmerkungen: value,
      }));
    }
    if (handleType === "speicherTitel") {
      setContainerState((prevState) => ({
        ...prevState,
        speicherTitel: value,
      }));
    }
    if (handleType === "category") {
      setContainerState((prevState) => ({
        ...prevState,
        category: value,
      }));
    }
    if (handleType === "assignedUsers") {
      setContainerState((prevState) => ({
        ...prevState,
        assignedUsers: value,
      }));
    }
    if (handleType === "createdBy") {
      setContainerState((prevState) => ({
        ...prevState,
        createdBy: value,
      }));
    }
  }

  const bruttoNettoCtxValues = {
    containerState,
    handleContainerState,
  };

  return (
    <BruttoNettoContext.Provider value={bruttoNettoCtxValues}>
      <div className="bnr_container">
        <BruttoNettoRSetup />
        {/* <h1>Rechen-Apps</h1>
        <div className="nk-lohnzettel">
          <img className="bnrLogo" src={BnrLogo} alt="logo"></img>
          <BruttoNettoRSetup />
        </div> */}
      </div>
    </BruttoNettoContext.Provider>
  );
}
