import React, { useRef } from "react";
import { useEffect, useContext } from "react";
import BruttoNettoContext from "../a_container/BruttoNettoContext";
import extEStCalc from "./extEStCalc";
import extSozVsgCalc from "./extSozVsgCalc";
import "./bnr.css";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

export default function Ergebnisse() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const safeHandlecontainerState = useRef(handleContainerState);

  const bruttoGehalt = containerState.bruttoGehalt;
  const abzugsBetrag = containerState.abzugsBetrag;
  const steuerAbzug = containerState.steuerAbzug;

  useEffect(() => {
    console.log("useEffect running");
    const abgabenBerechnen = () => {
      console.log("bruttoGehalt", bruttoGehalt);

      const { sVsgBeitrag, sVsgSatz } = extSozVsgCalc(bruttoGehalt);

      const einkommenNachAbzug = bruttoGehalt - abzugsBetrag - sVsgBeitrag;
      console.log("sVsgBeitrag", sVsgBeitrag);
      const berechneteSteuerVorAbzug = extEStCalc(einkommenNachAbzug);
      const gesamtSteuerVorAbzug = berechneteSteuerVorAbzug;
      let gesamtSteuer = gesamtSteuerVorAbzug - steuerAbzug;
      gesamtSteuer = Math.max(gesamtSteuer, 0);

      const nettoGehalt = bruttoGehalt - gesamtSteuer - sVsgBeitrag;
      console.log("nettoGehalt", nettoGehalt);

      safeHandlecontainerState.current("steuer", gesamtSteuer.toFixed(2));
      safeHandlecontainerState.current("sVsgBeitrag", sVsgBeitrag.toFixed(2));
      safeHandlecontainerState.current("sVsgSatz", sVsgSatz.toFixed(2));
      safeHandlecontainerState.current("nettoGehalt", nettoGehalt.toFixed(2));
    };

    abgabenBerechnen();
  }, [bruttoGehalt, abzugsBetrag, steuerAbzug, safeHandlecontainerState]);

  return (
    <div>
      <div style={{ "margin-top": "0.5rem" }} className="bnr_result bnr_trc2">
        SV-Satz
        <Tippy content={<p>In NÖ ohne Wohnbauförderungsumlage.</p>}>
          <span className="bnr_tooltip">ℹ️</span>
        </Tippy>
        :
        <span className="bnr_help_float_right">
          {containerState.sVsgSatz.toLocaleString("de-DE")}%
        </span>
      </div>
      <div className="bnr_result bnr_trc1">
        SV-Beitrag:
        <span className="bnr_help_float_right">
          {" "}
          {containerState.sVsgBeitrag.toLocaleString("de-DE")}€
        </span>
      </div>

      <div className="bnr_result bnr_trc2">
        Steuer:{" "}
        <span className="bnr_help_float_right">
          {containerState.steuer.toLocaleString("de-DE")}€
        </span>
      </div>

      <div className="bnr_result bnr_net-pay bnr_trc1">
        netto:
        <span className="bnr_help_float_right">
          {containerState.nettoGehalt.toLocaleString("de-DE")}€
        </span>
      </div>
    </div>
  );
}
