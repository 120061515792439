import "./ProDomo.css";
import "../../assets/WebFriesRahmen.svg";
import privatrecht from "../../assets/privatrecht.svg";
const Community_RL_Gallerie = [
  "/CommRLGallerie/crl0_.png",
  "/CommRLGallerie/crl1_.png",
  "/CommRLGallerie/crl2_.png",
  "/CommRLGallerie/crl3_.png",
  "/CommRLGallerie/crl4_.png",
];

export default function ProDomo() {
  return (
    <>
      {/* <div id="link_2" className="CommunityRL_Fries">
        <div className="fries_container">
          <span className="FriesLabel">Comm.</span>
          <img
            src={privatrecht}
            alt="dashboard icon"
            activeClassName="active"
          />
        </div>
      </div> */}
      {/* <div>
       <img src="CommRLGallerie/crl0_.png" alt="clr0"></img>
      </div> */}
      <h2 className="page-title">🏡 Pro Domo</h2>
      <nav id="link_0" className="prodomo_nav">
        <h2 className="section-title">▷ Verzeichnis</h2>
        <ul>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto"> 🗣👂 </span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_1">Kontakt </a>
          </li>

          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto_rechts"> 🏰 </span>
              <span className="Klammer">】</span>
            </li>
            <a href="#link_2">Community </a>
          </li>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto">🗝🌱</span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_2">Autorisierung & Beiträge </a>
          </li>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto">®</span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_2">Anteile </a>
          </li>

          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto">⚄</span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_3">Zuteilungshinweise </a>
          </li>
        </ul>
      </nav>
      <div id="link_1" className="CommunityRL_Fries">
        <div className="fries_container">
          <span className="FriesLabel">Kontakt</span>
          <img src={Community_RL_Gallerie[1]} alt="clr1"></img>
        </div>
      </div>
      <nav className="vz_backlink">
        <ul>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto"> ◀ </span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_0">Vz. </a>
          </li>
        </ul>
      </nav>
      <ul className="kontakt">
        <li>🗣👂Kontakt</li>
        <li>mit der 𐌇 Redaktion von</li>
        <li>◤ ◥</li>
        <li>◣ ◢</li>
        <li>Design-Team Concordia T.C.</li>
        <li>für</li>
        <li>#Anregungen</li>
        <li>#Anliegen</li>
        <li>#Beschwerden</li>
        <li>#Beitragseinreichung</li>
        <li> 📬 gome.mob@gmail.com</li>
      </ul>
      <div id="link_2" className="CommunityRL_Fries">
        <div className="fries_container">
          <span className="FriesLabel">Comm.</span>
          <img src="CommRLGallerie/crl0_.png" alt="clr0"></img>
        </div>

        <div className="fries_container">
          <span className="FriesLabel">Autsg. & Btg.</span>
          <img src={Community_RL_Gallerie[2]} alt="clr2"></img>
        </div>
        <div className="fries_container">
          <span className="FriesLabel">Anteile</span>
          <img src={Community_RL_Gallerie[3]} alt="clr3"></img>
        </div>
      </div>
      <nav className="vz_backlink">
        <ul>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto"> ◀ </span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_0">Vz. </a>
          </li>
        </ul>
      </nav>
      <table className="CommunityRL_table">
        <tr>
          <th>🏰Community </th>
          <td colSpan={3}>(Ausnahmen sind aus rechtl. Gründen mgl.)</td>
        </tr>
        <tr>
          <td>⚙Rollen </td>
          <td>
            🗝Autorisierung:
            <br />⌸ Admin
            <br />
            𐌇 Redakteur
            <br />⎕ Contributor
          </td>
          <td colSpan={2}>
            🌱 *Beitragsstatus:
            <br />
            ✴ Performer: 27+ Btg.
            <br />
            ✭ Sponsor: 9+ Btg.
            <br />
            ✦ Helfer: 3+ Btg.
            <br />* Beitrag ~1 Tag qual. Arbeit
          </td>
        </tr>
        <tr>
          <td>® Anteile</td>
          <td>
            ⌸ Admin 50 %**
            <br />
            𐌇 Redakteure 20 %
            <br />
            <br />
            **Aus soz Verantwortung,
            <br />
            um Zersplitterung zu verhindern
          </td>
          <td colSpan={2}>
            ✴ Performer ® 15 %<br />
            ✭ Sponsoren ® 10 %<br />
            ✦ Helfer ® 5 %<br />
          </td>
        </tr>

        <tr>
          <td> ⚄ Zuteilungen</td>
          <td>?</td>
          <td>?:Beiträge</td>
          <td>® Quote </td>
        </tr>
        <tr>
          <td>Team Concordia</td>
          <td>⌸ Steuerungsgruppe</td>
          <td>✴ 600</td>
          <td>65 %</td>
        </tr>

        <tr>
          <td> M.E.</td>
          <td>𐌇 bestellt</td>
          <td>✦ 5</td>
          <td>35 %</td>
        </tr>
        <tr>
          <td></td>

          <td></td>
          <td>605</td>
          <td></td>
        </tr>
      </table>
      <div id="link_3" className="CommunityRL_Fries">
        <div className="fries_container">
          <span className="FriesLabel">Zutlg.</span>
          <img src={Community_RL_Gallerie[4]} alt="clr4"></img>
        </div>
      </div>
      <nav className="vz_backlink">
        <ul>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto"> ◀ </span>
              <span className="Klammer_rechts">】</span>
            </li>
            <a href="#link_0">Vz. </a>
          </li>
        </ul>
      </nav>
      <div className="hinweis">
        <p>⚄ Zuteilungshinweise</p>
        <p>
          Besitz und Nutzung dieser Inhalte oder verwandter Module begründen
          keine rechtlichen Ansprüche, insbesondere keine Nutzungsrechte;
          selbige beruhen ausschließlich auf entsprechenden
          Einzelvereinbarungen. Dessen ungeachtet wird der allgemeinen
          Öffentlichkeit ein befristetes unentgeltliches Nutzungsrecht bis Ende
          2024 und befreundeten Organisationen ein solches Nutzungsrecht bis
          Ende 2025 erteilt.
        </p>
        <p>
          Das volle Urheberrecht ist für 30 Mio. € (in Kaufkraft anno 2022)
          käuflich erwerbbar (der Geschäftsvermittler erhält 10% Provision).
        </p>
      </div>
    </>
  );
}
