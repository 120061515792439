import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import Bookmark from "./Bookmark";

// styles
import "./KonvList.css";

export default function ProjectList({ projects }) {
  console.log(projects);

  return (
    <div className="project-list">
      {projects.length === 0 && <p>Noch keine Konversation!</p>}
      {projects.map((project) => (
        <div className="konvBlock">
          <div className="flexHeaderKonvBlock">
            <Link to={`/projects/${project.id}`} key={project.id}>
              <h4>{project.name}</h4>

              <p>
                erstellt am {project.dueDate.toDate().toLocaleDateString()} von{" "}
                {project.createdBy.displayName}
              </p>
            </Link>

            <Bookmark document={project} collection="projects" />
          </div>
          <div className="assigned-to">
            <p>
              <strong>im Gespräch:</strong>
            </p>
            <ul>
              {project.assignedUsersList.map((user) => (
                <li key={user.photoURL}>
                  <p>{user.displayName}</p>
                  <Avatar src={user.photoURL} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}
