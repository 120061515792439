import React from "react";
import { useCollection } from "../../../hooks/useCollection";
import { useState, useContext, useEffect, useRef } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import ReportContext from "../a_container/ReportContext";
import AbfrageZusammenfassung from "./AbfrageZusammenfassung.js";
import { Link } from "react-router-dom";
// components

// styles

export default function Report() {
  const { containerState, handleContainerState } = useContext(ReportContext);
  console.log(containerState);
  const [loadedDocs, setLoadedDocs] = useState("");
  let safeHandleContainerState = useRef(handleContainerState);

  let my_documents;
  const { user } = useAuthContext();
  const { documents, error } = useCollection("calculations");
  useEffect(() => {
    setLoadedDocs(documents);
    // alert("loaded docs");
  }, [documents]);

  // if (containerState.requestSubmitted) {
  if (loadedDocs) {
    my_documents = loadedDocs
      ? loadedDocs.filter((document) => {
          let assignedToMe = false;
          document.assignedUsersList.forEach((u) => {
            if (u.value.id === user.uid) {
              assignedToMe = true;
            }
          });
          return assignedToMe;
        })
      : null;

    my_documents = my_documents
      ? my_documents.filter((document) => {
          let assignedToMe = false;
          document.assignedUsersList.forEach((u) => {
            if (
              u.value.displayName === containerState.reportCritShared2 ||
              containerState.reportCritShared2 === "irgendwem"
            ) {
              assignedToMe = true;
            }
          });
          if (
            user.displayName === containerState.reportCritShared2 &&
            document.assignedUsersList.length > 1
          ) {
            assignedToMe = false;
          }
          return assignedToMe;
        })
      : null;

    my_documents = my_documents
      ? my_documents.filter((document) => {
          if (
            document.calcData.a01_Vorlage === containerState.reportCritCategory
          ) {
            return true;
          } else {
            return false;
          }
        })
      : null;

    my_documents = my_documents
      ? my_documents.filter((document) => {
          if (
            containerState.reportCritStartDate > 0 &&
            containerState.reportCritEndDate > 0
          ) {
            if (
              document.createdAt.toDate() >=
                containerState.reportCritStartDate &&
              document.createdAt.toDate() <= containerState.reportCritEndDate
            ) {
              return true;
            }
          } else {
            return true;
          }
        })
      : null;

    my_documents.sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate());
    console.log(my_documents);
  }
  // obj.calcData.a01_Vorlage
  // }

  let displayResults;
  let displayHeader;
  let tableData = [];
  let columnSums;

  if (my_documents && my_documents.length > 0) {
    // alert("display results");
    displayHeader = [];
    const pureHeaderSummary = my_documents[0].calcData;

    const sortedHeaderSummary = Object.keys(pureHeaderSummary).sort();
    delete sortedHeaderSummary[0];
    displayHeader = (
      <>
        <td>Formularart</td>
        <td>Erstellungsdatum</td>
        <td>Titel</td>

        {sortedHeaderSummary.map((key) => (
          <td key={key}>
            <span style={{ float: "right" }}>{key.substring(4)}</span>
          </td>
        ))}
      </>
    );

    displayResults = [];
    for (let formular of my_documents) {
      console.log(formular.speicherTitel);
      let tableDataRow = [];
      const pureCalcSummary = formular.calcData;
      const sortedCalcSummary = Object.keys(pureCalcSummary).sort();
      delete sortedCalcSummary[0];

      tableDataRow = sortedCalcSummary.map((key) => {
        const value = pureCalcSummary[key];
        if (typeof value === "string") {
          // alert("string");
          return value.replace(",", /\./g);
        } else {
          console.log(`Value for key ${key} is not a string:`, value);
          return value; // or handle it in some way
        }
      });
      tableData.push(tableDataRow);

      const calcSummaryFields = (
        <>
          {sortedCalcSummary.map((key) => (
            <td key={key}>
              <span style={{ float: "right" }}>{pureCalcSummary[key]}</span>
            </td>
          ))}
        </>
      );

      displayResults.push(
        <tr>
          <td>{formular.calcData.a01_Vorlage}</td>
          <td>{formular.createdAt.toDate().toLocaleDateString()}</td>

          <td>
            <Link
              to={`${formular.bearbeitungsPfad}:${formular.id}`}
              key={formular.id}
            >
              {formular.speicherTitel}
            </Link>
          </td>
          {calcSummaryFields}
        </tr>
      );
    }

    // tableData = [
    //   [1, 2, 3, 4, 5, 6],
    //   [7, 8, 9, 10, 11, 12],
    //   [13, 14, 15, 16, 17, 18],
    //   [19, 20, 21, 22, 23, 24],
    // ];

    columnSums = Array(tableData[0].length).fill(0);

    tableData.forEach((row) => {
      row.forEach((cell, index) => {
        // alert(cell);
        const numericCell = parseFloat(cell); // Convert cell to a number
        if (!isNaN(numericCell)) {
          columnSums[index] += numericCell;
        }
      });
    });
    columnSums.splice(0, 1);
  } else {
    displayResults = null;
  }

  return (
    { my_documents } && (
      <div>
        <h2>Rückgabewerte</h2>
        <br />
        <table>
          <thead className="report_header">
            <tr>{displayHeader}</tr>
          </thead>
          {displayResults}
        </table>
        <br />
        <br />
        <AbfrageZusammenfassung
          displayHeader={displayHeader}
          columnSums={columnSums}
        />
      </div>
    )
  );
}
