import { NavLink } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

// components
import Avatar from "./Avatar";

// styles & images
import "./Sidebar.css";
import werIstdaIcon from "../assets/ivk_persons.svg";
import ivk_house from "../assets/ivk_house.svg";
import { useEffect, useState } from "react";

import DashboardIcon from "../assets/dashboard_icon.svg";
import AddIcon from "../assets/add_icon.svg";
import SharedIcon from "../assets/shared_icon.svg";
import cloud_icon from "../assets/cloud_icon.svg";
import ivk_vwlt_prodomo from "../assets/ivk_vwlt_prodomo.svg";
import ivk_vwlt_wer_ist_da from "../assets/ivk_vwlt_wer_ist_da.svg";

import star_icon from "../assets/star_icon.svg";
import filter_icon from "../assets/filter_icon.svg";

import ivk_toogler from "../assets/ivk_toggler.svg";

export default function Sidebar() {
  const [width, setWidth] = useState(window.innerWidth);
  const [togglerVisible, setTogglerVisible] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [sidebarFixed, setSidebarFixed] = useState(false);

  const { user } = useAuthContext();
  const toggleToggler = () => {
    if (!sidebarVisible) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  useEffect(() => {
    if (width < 900) {
      setSidebarVisible(false);
      setTogglerVisible(true);
      setSidebarFixed(true);
    } else {
      setSidebarVisible(true);
      setTogglerVisible(false);
      setSidebarFixed(false);
    }
  }, [width]);

  return (
    <div
      className={
        sidebarFixed ? "sidebarContainer sidebarFixed" : "sidebarContainer  "
      }
    >
      <div className={sidebarVisible ? "sidebar" : "sidebar hidden "}>
        <span>width: {width}</span>
        <div className="sidebar-content">
          <div className="user">
            <Avatar src={user.photoURL} />
            <p>Hallo, {user.displayName}!</p>
          </div>
          <nav className="links">
            <ul>
              <li>
                <NavLink exact to="/prodomo" activeClassName="active">
                  <img
                    className="ivk_vwltg"
                    src={ivk_vwlt_prodomo}
                    alt="dashboard icon"
                  />
                  <span> Pro-Domo-Verwaltung</span>
                </NavLink>
              </li>
              <li className="sidebar_border">
                <NavLink exact to="/wer_ist_da" activeClassName="active">
                  <img
                    className="ivk_vwltg"
                    src={ivk_vwlt_wer_ist_da}
                    alt="dashboard icon"
                  />
                  <span> Wer-ist-da-Vwltg.</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink exact to="/priorisierte_konv">
                  <img
                    src={star_icon}
                    alt="dashboard icon"
                    activeClassName="active"
                  />
                  <span>💬 Priorisierte Konv.</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink exact to="/">
                  <span>💬 </span>
                  <img
                    src={DashboardIcon}
                    alt="dashboard icon"
                    activeClassName="active"
                  />
                  <span>Alle Konversationen</span>
                </NavLink>
              </li>
              <li className="sidebar_border">
                <NavLink to="/create">
                  <span>💬 </span>
                  <img
                    src={AddIcon}
                    alt="add project icon"
                    activeClassName="active"
                  />
                  <span>Neue Konversation</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink exact to="/priorisierte_formulare">
                  <img
                    src={star_icon}
                    alt="dashboard icon"
                    activeClassName="active"
                  />
                  <span>🧾 Priorisierte Form.</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink exact to="/kalkulationen" activeClassName="active">
                  <span>🧾</span>
                  <img src={DashboardIcon} alt="dashboard icon" />
                  <span>Alle Formulare</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/neue_kalkulation" activeClassName="active">
                  <span>🧾</span>
                  <img src={AddIcon} alt="add project icon" />
                  <span>Neues Formular</span>
                </NavLink>
              </li>
              <li className="sidebar_border">
                <NavLink to="/report" activeClassName="active">
                  <span>🧾</span>
                  <img src={filter_icon} alt="dashboard icon" />
                  <span> Auswtg. gtl. Form.</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/cloud_dokument" activeClassName="active">
                  <img src={cloud_icon} alt="dashboard icon" />
                  <span>Cloud-Links</span>
                </NavLink>
              </li>
              {/* <li>
              <NavLink
                exact
                to="/geteilte_Kalkulationen"
                activeClassName="active"
              >
                <img src={SharedIcon} alt="dashboard icon" />
                <span>🧾 Geteilte Kalkulationen</span>
              </NavLink>
            </li> */}
            </ul>
          </nav>
        </div>
      </div>
      <div
        onClick={toggleToggler}
        className={togglerVisible ? "sidebarToggler" : "sidebarToggler hidden "}
      >
        <div className="togglerSymbol">
          <img src={ivk_toogler} alt="ivk_toogler" />
        </div>
      </div>
    </div>
  );
}
