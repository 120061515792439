import React, { useRef } from "react";
import { useEffect, useContext } from "react";
import BruttoNettoContext from "../a_container/BruttoNettoContext";
import extDGZKmSFLAF from "./extDGZKmSFLAF";
import extDGZMV from "./extDGZMV";
import extDGZSV from "./extDGZSV";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

export default function ErgDGZ() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);

  const bruttoGehalt = containerState.bruttoGehalt;
  const safehandleContainerState = useRef(handleContainerState);

  useEffect(() => {
    console.log("useEffect running");
    const abgabenBerechnen = () => {
      const { kd, rabatt } = extDGZKmSFLAF(bruttoGehalt);
      safehandleContainerState.current("ergDGZkd", kd);
      safehandleContainerState.current("ergDGZkdr", rabatt);
      safehandleContainerState.current("ergDGZkdNetto", kd - rabatt);

      const dgSV = extDGZSV(bruttoGehalt);
      safehandleContainerState.current("ergDGSV", dgSV);

      const dgMV = extDGZMV(bruttoGehalt);
      safehandleContainerState.current("ergDGMV", dgMV);
      const dgZgesamt = dgSV + dgMV + kd - rabatt;
      safehandleContainerState.current("ergDGgesamt", dgZgesamt);
      console.log(containerState);
    };

    abgabenBerechnen();
  }, [bruttoGehalt, safehandleContainerState]);

  return (
    <div>
      <p className="bnr_helper_margin1 bnr_lead">
        <b>DG-Zuschläge lfd. & SZ</b>
      </p>
      <div className="bnr_result bnr_trc1">
        DG-Anteil SV{" "}
        <span className="bnr_tooltip" data-tooltip-id="tooltip10">
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.ergDGSV &&
            containerState.ergDGSV.toLocaleString("de-DE", {
              maximumFractionDigits: 2,
            })}
          €
        </span>
      </div>
      <div className="bnr_result bnr_trc2">
        KommS & FLAF{" "}
        <span className="bnr_tooltip" data-tooltip-id="tooltip11">
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.ergDGZkd &&
            containerState.ergDGZkd.toLocaleString("de-DE", {
              maximumFractionDigits: 2,
            })}
          €
        </span>
      </div>
      <div className="bnr_result bnr_trc1">
        KF-Rabatt{" "}
        <span className="bnr_tooltip" data-tooltip-id="tooltip12">
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.ergDGZkdr &&
            containerState.ergDGZkdr.toLocaleString("de-DE", {
              maximumFractionDigits: 2,
            })}
          €
        </span>
      </div>

      <div className="bnr_result bnr_trc2">
        Mitarbeitervsg.{" "}
        <span className="bnr_tooltip" data-tooltip-id="tooltip13">
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.ergDGMV &&
            containerState.ergDGMV.toLocaleString("de-DE", {
              maximumFractionDigits: 2,
            })}
          €
        </span>
      </div>
      <div className="bnr_net-cost bnr_trc1 bnr_result">
        gesamt:
        <span className="bnr_help_float_right">
          {containerState.ergDGgesamt &&
            containerState.ergDGgesamt.toLocaleString("de-DE", {
              maximumFractionDigits: 2,
            })}
          €
        </span>
      </div>
      {/* <ReactTooltip id="tooltip10" place="bottom" variant="info">
        <p>1,1% bei Geringfügigkeit, sonst 20,53% </p>
        <p>Keine LK-Kammerumlage in NÖ. </p>
      </ReactTooltip>
      <ReactTooltip id="tooltip11" place="bottom" variant="info">
        <p>3% Kommunalsteuer </p>
        <p>3,7% Dienstgeberbeitrag zum Familienlastenausgleichsfonds. </p>
      </ReactTooltip>
      <ReactTooltip id="tooltip12" place="bottom" variant="info">
        <p>
          Bis zu einer mtl. Lohnsumme von 1.460€ gilt ein Abzug von 1.095€.{" "}
        </p>
        <p>Dabei sind alle im Betrieb gezahlten Löhne maßgeblich.</p>
      </ReactTooltip>
      <ReactTooltip id="tooltip13" place="bottom" variant="info">
        <p>
          1,53% vom Entgelt, ab dem 2. Dienstmonat, ersetzt die Abfertigung alt.
        </p>
      </ReactTooltip> */}
    </div>
  );
}
