import React from "react";
import { useCollection } from "../../../hooks/useCollection";
import { useState, useContext, useEffect, useRef } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import ReportContext from "../a_container/ReportContext";

// components

// styles

export default function AbfrageKriterien() {
  const { containerState, handleContainerState } = useContext(ReportContext);

  const { user } = useAuthContext();
  const { documents, error } = useCollection("calculations");
  let safeHandleContainerState = useRef(handleContainerState);

  const {
    isPending,
    error: userError,
    documents: users,
  } = useCollection("users");

  const otherUsers = [];

  users &&
    users.forEach((u) => {
      otherUsers.push({ id: u.id, displayName: u.displayName });
    });

  const my_documents = documents
    ? documents.filter((document) => {
        let assignedToMe = false;
        document.assignedUsersList.forEach((u) => {
          if (u.value.id === user.uid) {
            assignedToMe = true;
          }
        });
        return assignedToMe;
      })
    : null;

  const form_categories = new Map();
  my_documents &&
    my_documents.forEach((obj) => {
      form_categories.set(obj.calcData.a01_Vorlage, obj.calcData.a01_Vorlage);
    });

  let otherUserOptions = otherUsers.map((o) => (
    <option key={o.id}>{o.displayName}</option>
  ));

  otherUserOptions = [
    ...otherUserOptions,
    <option key="irgendwem">irgendwem</option>,
  ];
  const category_options = Array.from(form_categories.entries()).map(
    ([key, value]) => (
      <option key={key} value={key}>
        {key} {/* Assuming 'name' is a property of the objects */}
      </option>
    )
  );
  useEffect(() => {
    if (my_documents && containerState.reportCritCategory === "") {
      safeHandleContainerState.current(
        "reportCritCategory",
        my_documents[0].calcData.a01_Vorlage
      );

      safeHandleContainerState.current("reportCritShared2", "irgendwem");
      safeHandleContainerState.current("reportCritShared1", user.displayName);
    }
  }, [my_documents, safeHandleContainerState]);

  return (
    <div>
      <h2>Abfragekriterien</h2>
      <br />
      <table>
        <thead className="report_header">
          <tr>
            <td>Formularart</td>
            <td>geteilt von</td>
            <td>geteilt von</td>

            <td>Beginn-Datum</td>

            <td>End-Datum</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select
                value={containerState.reportCritCategory}
                onChange={(e) =>
                  handleContainerState("reportCritCategory", e.target.value)
                }
              >
                {category_options}
              </select>
            </td>
            <td>{user.displayName}</td>
            <td>
              <select
                value={containerState.reportCritShared2}
                onChange={(e) =>
                  handleContainerState("reportCritShared2", e.target.value)
                }
              >
                {otherUserOptions}
              </select>
            </td>

            <td>
              <form>
                <input
                  placeholder="min"
                  onChange={(e) =>
                    handleContainerState("reportCritStartDate", e.target.value)
                  }
                ></input>
              </form>
            </td>

            <td>
              <form>
                <input
                  placeholder="max"
                  onChange={(e) =>
                    handleContainerState("reportCritEndDate", e.target.value)
                  }
                ></input>
              </form>
            </td>
          </tr>
          <tr>
            {" "}
            {/* <button
              onClick={(e) => handleContainerState("requestSubmitted", true)}
              className="btn kalk_speichern"
            >
              Laden
            </button> */}
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  );
}
