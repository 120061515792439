import { useState, useEffect } from "react";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import { timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import { useHistory } from "react-router";
import Select from "react-select";

// styles
import "./Create.css";

const categories = [
  { value: "Allerlei", label: "Allerlei" },
  { value: "Fachliches", label: "Fachliches" },
  { value: "Öffentlichkeit", label: "Öffentlichkeit" },
  { value: "Abstimmung", label: "Abstimmung" },
];

export default function Create() {
  const history = useHistory();
  const { addDocument, response } = useFirestore("projects");
  const { user } = useAuthContext();
  const { documents } = useCollection("users");
  const [users, setUsers] = useState([]);

  // form field values
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  //dueDate wird ab 11.5.2023 als Beginndat. der Konv. verwendet
  const [dueDate, setDueDate] = useState(new Date().toJSON().slice(0, 10));
  const [category, setCategory] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [formError, setFormError] = useState(null);

  // create user values for react-select
  useEffect(() => {
    if (documents) {
      setUsers(
        documents.map((user) => {
          return { value: { ...user, id: user.id }, label: user.displayName };
        })
      );
    }
  }, [documents]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);

    if (!category) {
      setFormError("Bitte ein Kategorie wählen.");
      return;
    }
    if (assignedUsers.length < 1) {
      setFormError("Bitte min. 1 Teilnehmer zuweisen.");
      return;
    }

    const assignedUsersList = assignedUsers.map((u) => {
      return {
        displayName: u.value.displayName,
        id: u.value.id,
      };
    });
    const createdBy = {
      displayName: user.displayName,
      id: user.uid,
    };

    const project = {
      name,
      details,
      assignedUsersList,
      bookmarkedBy: [],
      createdBy,
      category: category.value,
      dueDate: timestamp.fromDate(new Date(dueDate)),
      comments: [],
    };

    let addedDocumentId = await addDocument(project);

    if (!response.error) {
      history.push(`/projects/${addedDocumentId}`);
      // history.push("/");
    }
  };

  return (
    <div className="create-form">
      {/* <span>{dueDate}</span> */}
      <h2 className="page-title">💬 Neue Konversation</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Thema:</span>
          <input
            required
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </label>
        <label>
          <span>Details:</span>
          <textarea
            required
            onChange={(e) => setDetails(e.target.value)}
            value={details}
          ></textarea>
        </label>
        <label>
          <span>Beginn:</span>
          <input
            required
            type="date"
            onChange={(e) => setDueDate(e.target.value)}
            value={dueDate}
          />
        </label>
        <label>
          <span>Kategorie:</span>
          <Select
            onChange={(option) => setCategory(option)}
            options={categories}
            placeholder="bitte auswählen"
          />
        </label>
        <label>
          <span>Teilnehmer festlegen:</span>
          <Select
            placeholder="bitte sich auch selbst hinzuzufügen..."
            onChange={(option) => setAssignedUsers(option)}
            options={users}
            isMulti
          />
        </label>

        <button className="btn">Konversation erstellen</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
}
