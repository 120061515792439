import { useCollection } from "../../hooks/useCollection";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

// components
import KalkList from "../../components/KalkList";
import KalkFilter from "./kalk_KalkFilter";

// styles
import "./kalk_Dashboard.css";

export default function KalkDashboard() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection("calculations");

  const [filter, setFilter] = useState("bookmarks");

  function resetFilter(newFilter) {
    setFilter(newFilter);
  }
  const changeFilter = (newFilter) => {
    resetFilter(newFilter);
  };

  const my_documents = documents
    ? documents.filter((document) => {
        let assignedToMe = false;
        document.assignedUsersList.forEach((u) => {
          if (u.value.id === user.uid) {
            assignedToMe = true;
          }
        });
        return assignedToMe;
      })
    : null;

  const calculations = my_documents
    ? my_documents.filter((document) => {
        switch (filter) {
          case "bookmarks":
            console.log("bookmark filter");
            let bookmarked = false;
            try {
              document.bookmarkedBy.length > 0 &&
                document.bookmarkedBy.forEach((u) => {
                  if (u.id === user.uid) {
                    bookmarked = true;
                  }
                });
            } catch {}
            return bookmarked;

          case "24h_filter":
            // console.log(document);
            console.log(document.createdAt.toDate());
            console.log(Date.now() - 24 * 60 * 60 * 1000);
            if (
              document.createdAt.toDate() >
              Date.now() - 24 * 60 * 60 * 1000
            ) {
              return true;
            }

            // let in_timespan_24 = false;
            // document.comments.forEach((c) => {
            //   if (c.createdAt.toDate() > Date.now() - 24 * 60 * 60 * 1000) {
            //     in_timespan_24 = true;
            //   }
            // });
            // return in_timespan_24;
            break;
          case "48h_filter":
            console.log(document);
            if (
              document.createdAt.toDate() >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              return true;
            }

            // let in_timespan_48 = false;
            // document.comments.forEach((c) => {
            //   if (c.createdAt.toDate() > Date.now() - 48 * 60 * 60 * 1000) {
            //     in_timespan_48 = true;
            //   }
            // });
            // return in_timespan_48;
            break;

          case "alle":
            return true;
          case "allgemein":
          case "Lohnvg.":
          case "Beiträge":
          case "Steuern":
            console.log(document.category, filter);
            return document.category.value === filter;
          default:
            return true;
        }
      })
    : null;

  return (
    <div>
      <h2 className="page-title">🧾 Formulare</h2>
      {error && <p className="error">{error}</p>}
      {documents && <KalkFilter changeFilter={changeFilter} />}
      {calculations && (
        <KalkList calculations={calculations} changeFilter={changeFilter} />
      )}
    </div>
  );
}
