import React from "react";

export default function BootstrapComponent() {
  return (
    <></>
    // <div>
    //   <div className="jumbotron jumbotron-fluid">
    //     <div className="container">
    //       <h1 className="display-4">Bootstrap-Test-Bereich</h1>
    //       <p className="lead">
    //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
    //         recusandae voluptatibus, natus architecto asperiores a.
    //       </p>
    //     </div>
    //   </div>
    //   <div className="row">
    //     <div
    //       className=" col-md-8 col-lg-9 col-xl-10"
    //       style={{ border: "1px solid #333" }}
    //     >
    //       <ul>
    //         <li>6 column on small screens</li>
    //         <li>8 column on medium screen</li>
    //         <li>9 column on large screen</li>
    //         <li>10 column on xlarge screen</li>
    //       </ul>
    //     </div>
    //     <div
    //       className="col-md-4 col-lg-3 col-xl-2"
    //       style={{ border: "1px solid #333" }}
    //     >
    //       <ul>
    //         <li>6 column on small screens</li>
    //         <li>4 column on medium screen</li>
    //         <li>3 column on large screen</li>
    //         <li>2 column on xlarge screen</li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
  );
}
