import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";

// styles comment
import "./Signup.css";

export default function Signup() {
  const [invitation, setInvitation] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  // const [thumbnail, setThumbnail] = useState(null);
  // const [thumbnailError, setThumbnailError] = useState(null);
  const { signup, isPending, error } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (invitation === "Morgenrot") {
      signup(email, password, displayName);
    } else {
      return;
    }
  };

  // const handleFileChange = (e) => {
  //   setThumbnail(null);
  //   let selected = e.target.files[0];
  //   console.log(selected);

  //   if (!selected) {
  //     setThumbnailError("Please select a file");
  //     return;
  //   }
  //   if (!selected.type.includes("image")) {
  //     setThumbnailError("Selected file must be an image");
  //     return;
  //   }
  //   if (selected.size > 100000) {
  //     setThumbnailError("Image file size must be less than 100kb");
  //     return;
  //   }

  //   setThumbnailError(null);
  //   setThumbnail(selected);
  //   console.log("thumbnail updated");
  // };

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Anmeldung</h2>
      <label>
        <span>Einladung:</span>
        <input
          required
          type="password"
          onChange={(e) => setInvitation(e.target.value)}
          value={invitation}
        />
      </label>

      <label>
        <span>Email:</span>
        <input
          required
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </label>
      <label>
        <span>Passwort:</span>
        <input
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </label>
      <label>
        <span>angezeigter Name (Vorname & Initiale, z.B. "MaxM"):</span>
        <input
          required
          type="text"
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
        />
      </label>
      {/* <label>
        <span>Profile thumbnail:</span>
        <input required type="file" onChange={handleFileChange} />
        {thumbnailError && <div className="error">{thumbnailError}</div>}
      </label> */}
      {!isPending && <button className="btn">Anmelden</button>}
      {isPending && (
        <button className="btn" disabled>
          loading
        </button>
      )}
      {error && <div className="error">{error}</div>}
    </form>
  );
}
