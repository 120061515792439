import { useCollection } from "../../hooks/useCollection";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

// components
import ProjectList from "../../components/KonvList";
import ProjectFilter from "./ProjectFilter";

// styles
import "./Dashboard.css";

export default function Dashboard() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection("projects");
  const [filter, setFilter] = useState("bookmarks");

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const my_documents = documents
    ? documents.filter((document) => {
        let assignedToMe = false;
        document.assignedUsersList.forEach((u) => {
          if (u.id === user.uid) {
            assignedToMe = true;
          }
        });
        return assignedToMe;
      })
    : null;

  const projects = my_documents
    ? my_documents.filter((document) => {
        switch (filter) {
          case "bookmarks":
            console.log("bookmark filter");
            let bookmarked = false;
            try {
              document.bookmarkedBy.length > 0 &&
                document.bookmarkedBy.forEach((u) => {
                  if (u.id === user.uid) {
                    bookmarked = true;
                  }
                });
            } catch {}
            return bookmarked;

          case "24h_filter":
            console.log(document);
            console.log(document.createdAt.toDate());
            console.log(Date.now() - 24 * 60 * 60 * 1000);
            if (
              document.createdAt.toDate() >
              Date.now() - 24 * 60 * 60 * 1000
            ) {
              return true;
            }

            let in_timespan_24 = false;
            document.comments.forEach((c) => {
              if (c.createdAt.toDate() > Date.now() - 24 * 60 * 60 * 1000) {
                in_timespan_24 = true;
              }
            });
            return in_timespan_24;
          case "48h_filter":
            console.log(document);
            if (
              document.createdAt.toDate() >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              return true;
            }

            let in_timespan_48 = false;
            document.comments.forEach((c) => {
              if (c.createdAt.toDate() > Date.now() - 48 * 60 * 60 * 1000) {
                in_timespan_48 = true;
              }
            });
            return in_timespan_48;

          case "alle":
            return true;
          case "Allerlei":
          case "Fachliches":
          case "Öffentlichkeit":
          case "Abstimmung":
            console.log(document.category, filter);
            return document.category === filter;
          default:
            return true;
        }
      })
    : null;

  return (
    <div>
      <h2 className="page-title">💬 Konversationen</h2>
      {error && <p className="error">{error}</p>}
      {documents && <ProjectFilter changeFilter={changeFilter} />}
      {projects && <ProjectList projects={projects} />}
    </div>
  );
}
