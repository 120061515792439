import React, { useState, useEffect, useRef, useContext } from "react";
// import "./NkLohnzettel_laknö23.css";
import { useCollection } from "../../../../hooks/useCollection";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useFirestore } from "../../../../hooks/useFirestore";
import { useDocument } from "../../../../hooks/useDocument";
import BruttoNettoContext from "../a_container/MonatsPtkContext";
import Eingaben1 from "../c_module/Eingaben1";

import Eingaben2 from "../c_module/Eingaben2";
import Ergebnisse from "../c_module/Ergebnisse";
import MonatsPtkSaveMenu from "../c_module/MonatsPtkSaveMenu";
import KalkCommentsUniversal from "../../../../components/KalkCommentsUniversal";

import { useParams } from "react-router-dom";

const MonatsPtkSetup = () => {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const { user } = useAuthContext();
  let { calculationId } = useParams();
  if (calculationId) {
    calculationId = calculationId.slice(1);
  }
  const safeCalculationId = useRef(calculationId).current;
  const [update, setUpdate] = useState(false);

  console.log("user", user);

  console.log("calculation id", calculationId);
  console.log("safe calculation id", calculationId);

  useEffect(() => {
    if (calculationId) {
      handleContainerState("calculationId", calculationId);
    }
  }, [calculationId]);

  // ->problem start
  const { document: loadedDocument, error } = useDocument(
    "calculations",
    safeCalculationId
  );
  console.log("loaded document", loadedDocument);
  const { documents } = useCollection("users");
  console.log("loaded users", documents);
  console.log("containerState", containerState);

  useEffect(() => {
    if (documents && user) {
      handleContainerState(
        "users",
        documents.map((user) => ({
          value: { displayName: user.displayName, id: user.id },
          label: user.displayName,
        }))
      );
      handleContainerState("user", user);
    }
  }, [documents]);

  useEffect(() => {
    if (loadedDocument && documents) {
      console.log("loadedDocument", loadedDocument);

      handleContainerState("loadedDocument", loadedDocument);

      handleContainerState("documents", documents);
      handleContainerState(
        "users",
        documents.map((user) => ({
          value: { displayName: user.displayName, id: user.id },
          label: user.displayName,
        }))
      );
    }
  }, [loadedDocument, documents]);
  // ->problem ende

  useEffect(() => {
    if (documents && calculationId && loadedDocument && !update) {
      console.log(containerState.loadedDocument);
      // alert("is NO update");

      handleContainerState(
        "speicherTitel",
        containerState.loadedDocument.speicherTitel
      );
      handleContainerState(
        "anmerkungen",
        containerState.loadedDocument.anmerkungen
      );
      handleContainerState("category", containerState.loadedDocument.category);
      handleContainerState(
        "assignedUsers",
        containerState.loadedDocument.assignedUsersList
      );
      console.log(containerState.loadedDocument.assignedUsersList);
      handleContainerState(
        "inputValues",
        containerState.loadedDocument.inputValues
      );
      handleContainerState(
        "createdBy",
        containerState.loadedDocument.createdBy
      );
    }
  }, [containerState.loadedDocument, calculationId, update]);

  return (
    <>
      <Eingaben1 />
      <Ergebnisse />
      <Eingaben2 />
      <MonatsPtkSaveMenu />
      {containerState.loadedDocument && (
        <KalkCommentsUniversal
          calculation={containerState.loadedDocument}
        ></KalkCommentsUniversal>
      )}
    </>
  );
};

export default MonatsPtkSetup;
