import { useFirestore } from "../../../../hooks/useFirestore";

import { useHistory } from "react-router-dom";
import { useContext } from "react";
import BruttoNettoContext from "../a_container/BruttoNettoContext";

export default function useSubmit() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const { updateDocument, response: updateResponse } =
    useFirestore("calculations");
  const { addDocument: addNewDocument, response: useFirestoreResponse } =
    useFirestore("calculations");
  const history = useHistory();

  const handleSubmit = async (e, isUpdate) => {
    let addedDocumentId = "";
    // alert(isUpdate);
    e.preventDefault();
    handleContainerState("formError", null);
    handleContainerState("update", isUpdate);

    if (!containerState.category) {
      handleContainerState("formError", "Bitte eine Kategorie wählen.");

      return;
    }

    if (containerState.assignedUsers.length < 1) {
      handleContainerState(
        "formError",
        "Bitte mindestens 1 Teilnehmer zuweisen."
      );
      return;
    }
    const assignedUsers = containerState.assignedUsers;

    // const assignedUsersList = assignedUsers.map((u) => ({
    //   displayName: u.value.displayName,
    //   id: u.value.id,
    // }));

    const createdBy = {
      displayName: containerState.user.displayName,
      id: containerState.user.uid,
    };

    const calcSummary = {
      a01_Vorlage: "Landarbeiter NÖ, Stand 1.1.2023",
      a02_Monatslohn: containerState.bruttoGehalt,
      a03_Abzugsbetrag: containerState.abzugsBetrag,
      a04_Absetzbetrag: containerState.steuerAbzug,
      a05_Sozialversicherungsbeitrag: containerState.sVsgBeitrag,
      a06_Sozialversicherungssatz: containerState.sVsgSatz,
      a07_Steuer: containerState.steuer,
      a08_Nettolohn: containerState.nettoGehalt,
    };

    const calculation = {
      speicherTitel: containerState.speicherTitel,
      bearbeitungsPfad: "/neue_kalkulation/nk_lohnzettel_laknö23",
      anmerkungen: containerState.anmerkungen,
      assignedUsersList: assignedUsers,
      createdBy: createdBy,
      category: containerState.category,
      calcData: calcSummary,
      monatlichesEinkommen: containerState.bruttoGehalt,
      abzugsbetrag: containerState.abzugsBetrag,
      steuerabzug: containerState.steuerAbzug,
      comments: [],
    };
    console.log(calculation);
    if (isUpdate) {
      // alert(calculation.calcData[0].a01_Vorlage);
      // alert("is update");

      try {
        await updateDocument(containerState.calculationId, {
          speicherTitel: containerState.speicherTitel,
          anmerkungen: containerState.anmerkungen,
          assignedUsersList: containerState.assignedUsers,
          createdBy: createdBy,
          category: containerState.category,
          calcData: calcSummary,
          monatlichesEinkommen: containerState.bruttoGehalt,
          abzugsbetrag: containerState.abzugsBetrag,
          steuerabzug: containerState.steuerAbzug,
        });

        // Handle successful update
      } catch (error) {
        handleContainerState("formError", "update fehlgeschlagen");
      }
    } else {
      addedDocumentId = await addNewDocument(calculation);
    }

    if (isUpdate && !updateResponse.error) {
      console.log(updateResponse);
      history.push(`/calculations/${containerState.calculationId}`);
    }
    if (!isUpdate && !useFirestoreResponse.error) {
      console.log(useFirestoreResponse);
      history.push(`/calculations/${addedDocumentId}`);
    }
  };

  return { handleSubmit };
}
