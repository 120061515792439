import Avatar from "../../components/Avatar";
import { useFirestore } from "../../hooks/useFirestore";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link } from "react-router-dom";

export default function KalkSummary({ calculation, id }) {
  const { deleteDocument } = useFirestore("calculations");
  const { user } = useAuthContext();
  const history = useHistory();

  const handleClick = () => {
    deleteDocument(calculation.id);
    history.push("/kalkulationen");
  };
  const pureCalcSummary = calculation.calcData;
  const sortedCalcSummary = Object.keys(pureCalcSummary).sort();
  // console.log(calculation.calcData[0]);
  // console.log(sortedCalcSummary);
  console.log(calculation);
  return (
    <div>
      <span>{id}</span>
      <div className="calculation-summary">
        <h2 className="page-title">{calculation.speicherTitel}</h2>
        <p className="due-date">
          erstellt am {calculation.createdAt.toDate().toLocaleDateString()} von{" "}
          {calculation.createdBy.displayName}
        </p>
        <p className="details"> Kalkulationswerte: </p>

        <div>
          <ul>
            {sortedCalcSummary.map((key) => (
              <li key={key}>
                <strong style={{ float: "left" }}>{key.substring(4)}</strong>:{" "}
                <span style={{ float: "right" }}>{pureCalcSummary[key]}</span>
              </li>
            ))}
          </ul>
        </div>
        {/* {calculation.calcData.map((key) => (
          
          <div>
            <p>{Object.keys(key)}</p>
            <p>{Object.values(key)}</p>
          </div>
        ))} */}
        <p className="details"> Anmerkungen: </p>
        <p className="details">{calculation.anmerkungen}</p>
        <h4>geteilt mit</h4>
        <div className="assigned-users">
          {calculation.assignedUsersList.map((user) => (
            <div key={user.value.id}>
              <p>{user.value.displayName}</p>
              <Avatar src={user.photoURL} />
            </div>
          ))}
        </div>
      </div>
      {user.uid === calculation.createdBy.id && (
        <>
          <button className="btn" onClick={handleClick}>
            Kalkulation löschen
          </button>
          <button className="btn">
            <Link to={`${calculation.bearbeitungsPfad}:${id}`}>Update</Link>
          </button>
        </>
      )}
    </div>
  );
}
