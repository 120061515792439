export default function extEStCalcSZ(einkommen) {
  let dg_sv;
  if (einkommen > 5850) {
    einkommen = 5850;
  }
  if (einkommen > 500.91) {
    dg_sv = einkommen * 0.2053;
  } else {
    dg_sv = einkommen * 0.012;
  }
  return dg_sv;
}
