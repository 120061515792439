import React from "react";
import { useContext, useState } from "react";
import MonatatsPktContext from "../a_container/MonatsPtkContext";
import Select from "react-select";

export default function Eingaben2() {
  const { containerState, handleContainerState } =
    useContext(MonatatsPktContext);
  const handleInputChange = (e) => {
    const inputId = e.target.id;
    const inputValue = e.target.value;
    const newInputValues = {
      ...containerState.inputValues,
      [inputId]: inputValue,
    };

    handleContainerState("inputValues", newInputValues);
  };
  return (
    <div>
      {Array.from({ length: 31 }, (_, index) => {
        const number = index + 1;
        const id = number.toString().padStart(2, "0");

        return (
          <div className="monatsptk_row" key={`row_${id}`}>
            <div className="monatsptk_date">
              <input
                id={`mp${id}_1`}
                type="text"
                onChange={handleInputChange}
                value={containerState.inputValues[`mp${id}_1`] || ""}
              />
            </div>
            <div className="monatsptk_werte">
              <input
                id={`mp${id}_2`}
                type="text"
                onChange={handleInputChange}
                value={containerState.inputValues[`mp${id}_2`] || ""}
              />
            </div>
            <div className="monatsptk_werte">
              <input
                id={`mp${id}_3`}
                type="text"
                onChange={handleInputChange}
                value={containerState.inputValues[`mp${id}_3`] || ""}
              />
            </div>
            <div className="monatsptk_werte">
              <input
                id={`mp${id}_4`}
                type="text"
                onChange={handleInputChange}
                value={containerState.inputValues[`mp${id}_4`] || ""}
              />
            </div>
            <div className="monatsptk_note">
              <textarea
                id={`mp${id}_5`}
                rows="1"
                onChange={handleInputChange}
                value={containerState.inputValues[`mp${id}_5`] || ""}
              ></textarea>
            </div>
          </div>
        );
      })}
      {/* 
      {containerState.storedRows.length > 0 && (
        <div>
          {containerState.storedRows.map((row, index) => (
            <div className="monatsptk_row" key={`stored_row_${index}`}>
              <div className="monatsptk_date">{row.mp1}</div>
              <div className="monatsptk_werte">{row.mp2}</div>
              <div className="monatsptk_werte">{row.mp3}</div>
              <div className="monatsptk_werte">{row.mp4}</div>
              <div className="monatsptk_note">{row.mp5}</div>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}
