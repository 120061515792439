import { useState } from "react";
import BellIcon from "../../assets/bell_icon.svg";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";

const filterList = ["alle", "allgemein", "Lohnvg.", "Beiträge", "Steuern"];

export default function KalkFilter({ changeFilter }) {
  const [currentFilter, setCurrentFilter] = useState("bookmarks");

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter);
    changeFilter(newFilter);
  };

  return (
    <div className="calculation-filter">
      <nav>
        <p>Filtern nach: </p>
        <button
          className={currentFilter === "bookmarks" ? "active" : ""}
          // className="bell-box"
          key="bookmarks"
          onClick={() => handleClick("bookmarks")}
        >
          <FaRegBookmark />

          <span> Bookmarks</span>
        </button>{" "}
        <button
          className={
            currentFilter === "24h_filter" ? "bell-box active" : "bell-box"
          }
          // className="bell-box"
          key="24h_filter"
          onClick={() => handleClick("24h_filter")}
        >
          <img class="bell-icon" src={BellIcon} alt="Glocke" />
          <span> seit 24h</span>
        </button>
        <button
          className={
            currentFilter === "48h_filter" ? "bell-box active" : "bell-box"
          }
          // className="bell-box"
          key="48h_filter"
          onClick={() => handleClick("48h_filter")}
        >
          <img className="bell-icon" src={BellIcon} alt="Glocke" />
          <span> seit 48h</span>
        </button>
        {filterList.map((f) => (
          <button
            key={f}
            onClick={() => handleClick(f)}
            className={currentFilter === f ? "active" : ""}
          >
            {f}
          </button>
        ))}
      </nav>
    </div>
  );
}
