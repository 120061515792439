export default function extEStCalc(einkommen) {
  const klassen = [
    { min: 0, max: 11693 / 12, satz: 0 },
    { min: 11693.01 / 12, max: 19134 / 12, satz: 0.2 },
    { min: 19134.01 / 12, max: 32076 / 12, satz: 0.3 },
    { min: 32076.01 / 12, max: 62082 / 12, satz: 0.41 },
    { min: 62082.01 / 12, max: 93123 / 12, satz: 0.48 },
    { min: 93123.01 / 12, max: 1000000 / 12, satz: 0.5 },
    { min: 1000000.01 / 12, max: Infinity, satz: 0.55 },
  ];
  console.log("einkommenSteuerBerechnen");
  let berechneteSteuer = 0;

  for (let i = 0; i < klassen.length; i++) {
    const klasse = klassen[i];

    if (einkommen >= klasse.min) {
      const zuVersteuernderBetrag = Math.min(
        einkommen - klasse.min,
        klasse.max - klasse.min
      );
      berechneteSteuer += zuVersteuernderBetrag * klasse.satz;
    }
  }

  return berechneteSteuer;
}
