export default function extSozVsgCalcSZ(bruttoGehalt) {
  const sozialversicherungsKlassen = [
    { min: 0, max: 500.91, satz: 0 },
    { min: 500.92, max: 1885, satz: 0.1412 },
    { min: 1885.01, max: 2056, satz: 0.1512 },
    { min: 2056.01, max: 2228, satz: 0.1612 },
    { min: 2228.91, max: 5850, satz: 0.1712 },
  ];

  let sVsgBeitrag = 0;
  let sVsgSatz = 0;

  for (let i = 0; i < sozialversicherungsKlassen.length; i++) {
    const klasse = sozialversicherungsKlassen[i];

    if (bruttoGehalt >= klasse.min && bruttoGehalt <= klasse.max) {
      sVsgBeitrag = klasse.satz * bruttoGehalt;
      sVsgSatz = klasse.satz * 100;
      break;
    } else if (bruttoGehalt > klasse.max) {
      sVsgBeitrag = klasse.satz * klasse.max;
      sVsgSatz = klasse.satz * 100;
    }
  }

  return { sVsgBeitrag, sVsgSatz };
}
