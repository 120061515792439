import React from "react";
import { useCollection } from "../../../hooks/useCollection";
import { useState, useContext } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import ReportContext from "../a_container/ReportContext";

// components

// styles

export default function AbfrageZusammenfassung({ displayHeader, columnSums }) {
  const { containerState, handleContainerState } = useContext(ReportContext);
  console.log(columnSums);
  return (
    <div>
      <h2>Summen</h2>
      <br />
      <table>
        <thead className="report_header">
          {displayHeader && displayHeader}
        </thead>
        <tr>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          {columnSums &&
            columnSums.map((v) => (
              <td>
                <span style={{ float: "right" }}>{v.toFixed(2)}</span>
              </td>
            ))}
        </tr>
      </table>

      <br />
      <br />
    </div>
  );
}
