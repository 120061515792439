import React, { useState, useEffect } from "react";

const NkGsvg = () => {
  const [income, setIncome] = useState(0);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    calculateTax();
  }, []);

  const handleIncomeChange = (event) => {
    setIncome(event.target.value);
  };

  const calculateTax = () => {
    // Tax calculation logic goes here
    const taxAmount = income * 0.2; // Assuming a 20% tax rate
    setTax(taxAmount);
  };

  return (
    <div>
      <h2>Abgabenrechner</h2>
      <div>
        <label htmlFor="income">Einkommen: </label>
        <input
          id="income"
          type="number"
          value={income}
          onChange={handleIncomeChange}
        />
      </div>
      <button onClick={calculateTax}>Berechnen</button>
      {tax > 0 && (
        <div>
          <p>Abgabe: {tax}</p>
        </div>
      )}
    </div>
  );
};

export default NkGsvg;
