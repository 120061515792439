import React, { useContext } from "react";
import MonatatsPktContext from "../a_container/MonatsPtkContext";

export default function Eingaben1() {
  const { containerState, handleContainerState } =
    useContext(MonatatsPktContext);
  console.log("containerState", containerState);
  return (
    <>
      <h2>Monatsprotokoll</h2>
      <div className="monatsptk_header">Titel:</div>
      <div className="monatsptk_row monatsptk_header">
        <div className="monatsptk_titel">
          <input
            value={containerState.speicherTitel}
            onChange={(e) =>
              handleContainerState("speicherTitel", e.target.value)
            }
            id="mp00_1"
            type="text"
          />
        </div>
      </div>
      <div className="monatsptk_header">Anmerkungen:</div>
      <div className="monatsptk_row monatsptk_header">
        <div className="monatsptk_titel">
          <textarea
            value={containerState.anmerkungen}
            onChange={(e) =>
              handleContainerState("anmerkungen", e.target.value)
            }
            id="mp00_2"
            type="text"
          ></textarea>
        </div>
      </div>

      <div className="monatsptk_row monatsptk_header">
        <div className="monatsptk_date">Datum</div>
        <div className="monatsptk_werte">Spalte 1</div>
        <div className="monatsptk_werte">Spalte 2</div>
        <div className="monatsptk_werte">Spalte 3</div>
        <div className="monatsptk_note">Notizen</div>
      </div>
    </>
  );
}
