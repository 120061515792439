import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import Bookmark from "./Bookmark";

// styles
import "./KalkList.css";

export default function KalkList({ calculations, changeFilter }) {
  console.log(calculations);

  return (
    <div className="calculation-list">
      {calculations.length === 0 && <p>Noch keine Kalkulation!</p>}
      {calculations.map((calculation) => (
        <div className="konvBlock">
          <div className="flexHeaderKonvBlock">
            <Link
              to={`${calculation.bearbeitungsPfad}:${calculation.id}`}
              key={calculation.id}
            >
              <h4>{calculation.speicherTitel}</h4>
              <p>
                erstellt am{" "}
                {calculation.createdAt.toDate().toLocaleDateString()} von{" "}
                {calculation.createdBy.displayName}
              </p>
            </Link>
            <Bookmark
              document={calculation}
              changeFilter={changeFilter}
              collection="calculations"
            />
          </div>

          <div className="assigned-to">
            <p>
              <strong>geteilt mit:</strong>
            </p>
            <ul>
              {calculation.assignedUsersList.map((user) => (
                <li key={user.photoURL}>
                  <p>{user.value.displayName}</p>
                  <Avatar src={user.photoURL} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}
