import "./wer_ist_da.css";
import "../../assets/WebFriesRahmen.svg";
import OnlineUsers from "../../components/OnlineUsers";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function WerIstDa() {
  const { user } = useAuthContext();

  return <>{user && <OnlineUsers />}</>;
}
