import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";

// components

import KalkSummary from "./KalkSummary";
import CalculationComments from "./KalkComments";
// styles
import "./kalk_einzelansicht.css";

export default function KalkEinzelansicht() {
  const { id } = useParams();
  const { document, error } = useDocument("calculations", id);

  if (error) {
    return <div className="error">{error}</div>;
  }
  if (!document) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="calculation-details">
      <KalkSummary calculation={document} id={id} />
      <CalculationComments calculation={document} />
    </div>
  );
}
