import React, { useRef } from "react";
import { useEffect, useContext } from "react";
import BruttoNettoContext from "../a_container/BruttoNettoContext";
import extSozVsgCalcSZ from "./extSozVsgCalcSZ";
import extEStCalcSZ from "./extEStCalcSZ";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

export default function ErgSz14() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const bruttoGehalt = containerState.bruttoGehalt;
  const freiBetrag = containerState.szEStFreiBtg;

  const safehandleContainerState = useRef(handleContainerState);

  useEffect(() => {
    console.log("useEffect running");
    const abgabenBerechnen = () => {
      console.log("bruttoGehalt", bruttoGehalt);

      const { sVsgBeitrag, sVsgSatz } = extSozVsgCalcSZ(bruttoGehalt);
      safehandleContainerState.current("sz14SVsgSatz", sVsgSatz.toFixed(2));
      safehandleContainerState.current(
        "sz14SVsgBeitrag",
        sVsgBeitrag.toFixed(2)
      );

      const einkommenNachAbzug = bruttoGehalt - sVsgBeitrag;
      const steuerSZ14 = extEStCalcSZ(einkommenNachAbzug, freiBetrag);
      safehandleContainerState.current("steuerSZ14", steuerSZ14.toFixed(2));
      safehandleContainerState.current(
        "nettoSZ14",
        (bruttoGehalt - sVsgBeitrag - steuerSZ14).toFixed(2)
      );

      console.log(containerState);

      //   safehandleContainerState.current("steuer", gesamtSteuer.toFixed(2));
      //   safehandleContainerState.current("sVsgBeitrag", sVsgBeitrag.toFixed(2));
      //   safehandleContainerState.current("sVsgSatz", sVsgSatz.toFixed(2));
      //   safehandleContainerState.current("nettoGehalt", nettoGehalt.toFixed(2));
    };

    abgabenBerechnen();
  }, [bruttoGehalt, safehandleContainerState, freiBetrag]);

  return (
    <div>
      <p className="bnr_dg_zuschläge bnr_lead">
        <b>SZ 14. Monatslohn</b>
      </p>
      <div className="bnr_result bnr_trc2">
        SV-Satz
        <span className="bnr_tooltip" data-tooltip-id="tooltip6">
          {" "}
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.sz14SVsgSatz.toLocaleString("de-DE")}%
        </span>
      </div>
      <div className="bnr_result bnr_trc1">
        SV-Beitrag:
        <span className="bnr_help_float_right">
          {containerState.sz14SVsgBeitrag.toLocaleString("de-DE")}€
        </span>
      </div>

      <div className="bnr_result bnr_trc2">
        Steuer
        <span className="bnr_tooltip" data-tooltip-id="tooltip7">
          {" "}
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.steuerSZ14.toLocaleString("de-DE")}€
        </span>
      </div>

      <div className="bnr_result bnr_net-pay bnr_trc1">
        netto:
        <span className="bnr_help_float_right">
          {containerState.nettoSZ14.toLocaleString("de-DE")}€
        </span>
      </div>
      {/* <ReactTooltip id="tooltip6" place="bottom" variant="info">
        <p>Keine LAK-Umlage bei SZ.</p>
      </ReactTooltip>
      <ReactTooltip id="tooltip7" place="bottom" variant="info">
        <p>620 € Freibetrag für Jahressechstel und Besteuerung mit 6%.</p>
      </ReactTooltip> */}
    </div>
  );
}
