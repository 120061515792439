import { useState } from "react";
import React from "react";
import "./report.css";

import ReportContext from "./ReportContext";

import AbfrageKriterien from "../c_module/AbfrageKriterien.js";
import AbfrageErgebnisse from "../c_module/AbfrageErgebnisse.js";
import AbfrageZusammenfassung from "../c_module/AbfrageZusammenfassung.js";

const initialcontainerState = {
  reportCritCategory: "",
  reportCritShared1: "",
  reportCritShared2: "keine Auswahl",
  reportCritStartDate: "",
  reportCritEndDate: "",
  user: "",
  otherUsers: "",
  requestSubmitted: false,
  requestPending: false,
  abgefragteDokumente: "",
  reportHeader: "",
};

function parseDate(dateString) {
  const parts = dateString.split(".");
  // Note: months are 0-based in JavaScript Dates, so we need to subtract 1 from the month
  const year = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[0], 10);

  return new Date(year, month, day);
}

export default function ReportContainer() {
  const [containerState, setContainerState] = useState(initialcontainerState);

  function handleContainerState(handleType, value) {
    if (handleType === "reportCritCategory") {
      setContainerState((prevState) => ({
        ...prevState,
        reportCritCategory: value,
      }));
    }
    if (handleType === "reportHeader") {
      setContainerState((prevState) => ({
        ...prevState,
        reportHeader: value,
      }));
    }

    if (handleType === "reportCritShared1") {
      setContainerState((prevState) => ({
        ...prevState,
        reportCritShared1: value,
      }));
    }
    if (handleType === "reportCritShared2") {
      setContainerState((prevState) => ({
        ...prevState,
        reportCritShared2: value,
      }));
    }
    if (handleType === "reportCritStartDate") {
      setContainerState((prevState) => ({
        ...prevState,
        reportCritStartDate: parseDate(value),
      }));
    }

    if (handleType === "reportCritEndDate") {
      let dateK = parseDate(value);
      dateK.setDate(dateK.getDate() + 1);

      setContainerState((prevState) => ({
        ...prevState,
        reportCritEndDate: dateK,
      }));
    }
    if (handleType === "user") {
      setContainerState((prevState) => ({
        ...prevState,
        user: value,
      }));
    }
    if (handleType === "otherUsers") {
      setContainerState((prevState) => ({
        ...prevState,
        otherUsers: value,
      }));
    }
    if (handleType === "requestSubmitted") {
      setContainerState((prevState) => ({
        ...prevState,
        requestSubmitted: value,
      }));
    }

    if (handleType === "requestPending") {
      setContainerState((prevState) => ({
        ...prevState,
        requestPending: value,
      }));
    }
    if (handleType === "abgefragteDokumente") {
      setContainerState((prevState) => ({
        ...prevState,
        abgefragteDokumente: value,
      }));
    }
  }

  const reportCtxValues = {
    containerState,
    handleContainerState,
  };

  return (
    <ReportContext.Provider value={reportCtxValues}>
      <div className="report_container">
        <AbfrageKriterien />
        <AbfrageErgebnisse />
      </div>
    </ReportContext.Provider>
  );
}
