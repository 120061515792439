export default function extEStCalcSZ(einkommen) {
  //3.7% db flaf, 3 % kommunalsteuer
  let kd = einkommen * 0.067;
  let kd_reduziert;

  let rabatt = 0;

  if (einkommen < 1095.01) {
    kd_reduziert = 0;
  } else if (einkommen > 1095 && einkommen < 1460.01) {
    kd_reduziert = (einkommen - 1095) * 0.067;
  } else {
    kd_reduziert = einkommen * 0.067;
  }
  if (kd_reduziert < kd) {
    rabatt = kd - kd_reduziert;
  } else {
    rabatt = 0;
  }
  return { kd: kd, rabatt: rabatt };
}
