import { createContext } from "react";
const ReportContext = createContext({
  reportCritCategory: "",
  reportCritShared1: "",
  reportCritShared2: "",
  reportCritStartDate: "",
  reportCritEndDate: "",
});

export default ReportContext; // making it available outside of its file
