import { NavLink } from "react-router-dom";

// styles & images
import "./Neue_Kalkulation.css";

export default function NeueKalkulation() {
  return (
    <div>
      <h2 className="page-title">🧾 Neues Formular</h2>
      <div className="NeueKalkulationVerzeichnis">
        <nav>
          <ul>
            <li>
              <div id="link_1" className="CommunityRL_Fries">
                <div className="fries_container">
                  <span className="FriesLabel">Allgemein</span>
                  <img
                    src="/Neue_Kalkulation/allgFormulare.png"
                    alt="clr1"
                  ></img>
                </div>
              </div>
              <li>
                <NavLink
                  exact
                  to="/neue_kalkulation/nk_monatsprotokoll_comp"
                  active="neue_kalkulation"
                >
                  <span>Monatsprotokoll modularisiert</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/neue_kalkulation/nk_monatsprotokoll"
                  active="neue_kalkulation"
                >
                  <span>Monatsprotokoll</span>
                </NavLink>
              </li>
            </li>

            <div id="link_1" className="CommunityRL_Fries">
              <div className="fries_container">
                <span className="FriesLabel">Lohnvg.</span>
                <img
                  src="/Neue_Kalkulation/lohnverrechnung.png"
                  alt="clr1"
                ></img>
              </div>
            </div>
            <li>
              <NavLink
                exact
                to="/neue_kalkulation/nk_lab_comp"
                active="neue_kalkulation"
              >
                <span>BNR LA modularisiert</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                exact
                to="/neue_kalkulation/nk_lohnzettel_laknö23"
                active="neue_kalkulation"
              >
                <span>Brutto-Netto: Landarbeiter NÖ (1.1.2023)</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/neue_kalkulation/nk_lohnzettel_ak23"
                active="neue_kalkulation"
              >
                <span>Brutto-Netto: Nicht-Landarbeiter (1.1.2023)</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/neue_kalkulation/nk_lohnzettel_ak23"
                active="neue_kalkulation"
              >
                <span>Zeiterfassung</span>
              </NavLink>
            </li>
            <div id="link_1" className="CommunityRL_Fries">
              <div className="fries_container">
                <span className="FriesLabel">SV Selbständige</span>
                <img
                  src="/Neue_Kalkulation/sv_selbstaendige.png"
                  alt="clr1"
                ></img>
              </div>
            </div>
            <li>
              <NavLink exact to="/neue_kalkulation/nk_bsvg">
                <span>BSVG-Beitrag</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/neue_kalkulation/nk_gsvg">
                <span>GSVG-Beitrag</span>
              </NavLink>
            </li>
            <div id="link_1" className="CommunityRL_Fries">
              <div className="fries_container">
                <span className="FriesLabel">Steuern</span>
                <img src="/Neue_Kalkulation/steuer.png" alt="clr1"></img>
              </div>
            </div>
            <li>
              <NavLink
                exact
                to="/neue_kalkulation/nk_est"
                active="neue_kalkulation"
              >
                <span>Einkommensteuer allg.</span>
              </NavLink>
            </li>
          </ul>
        </nav>
        <div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
