import { useState } from "react";
import React from "react";
import "./MonatsPtk.css";

import MonatsPtkContext from "./MonatsPtkContext";
import BnrLogo from "../../../../assets/BnrLogo.svg";

import MonatsPtkSetup from "../b_setup/MonatsPtkSetup.js";

const initialcontainerState = {
  formError: "",
  storedRows: "",
  createdBy: "",
  inputValues: "",
  users: "",
  anmerkungen: "",
  category: "",
  assignedUsers: "",
  safeCalculationId: "",
  calculationId: "",
  user: "",
  speicherTitel: "",
  document: "",
  categories: [
    { value: "Lohnvg.", label: "Lohnvg." },
    { value: "Beiträge", label: "Beiträge" },
    { value: "Steuern", label: "Steuern" },
    { value: "Sonstiges", label: "Sonstiges" },
  ],
  sums: { sum1: 2, sum2: 3, sum3: 4 },
};

export default function MonatsPtkContainer() {
  const [containerState, setContainerState] = useState(initialcontainerState);

  function handleContainerState(handleType, value) {
    if (handleType === "createdBy") {
      setContainerState((prevState) => ({
        ...prevState,
        createdBy: value,
      }));
    }
    if (handleType === "formError") {
      setContainerState((prevState) => ({
        ...prevState,
        formError: value,
      }));
    }
    if (handleType === "users") {
      setContainerState((prevState) => ({
        ...prevState,
        users: value,
      }));
    }

    if (handleType === "anmerkungen") {
      setContainerState((prevState) => ({
        ...prevState,
        anmerkungen: value,
      }));
    }
    if (handleType === "category") {
      setContainerState((prevState) => ({
        ...prevState,
        category: value,
      }));
    }
    if (handleType === "assignedUsers") {
      setContainerState((prevState) => ({
        ...prevState,
        assignedUsers: value,
      }));
    }

    if (handleType === "loadedDocument") {
      setContainerState((prevState) => ({
        ...prevState,
        loadedDocument: value,
      }));
    }
    if (handleType === "bruttoGehalt") {
      console.log("state update: bruttoGehalt");

      setContainerState((prevState) => ({
        ...prevState,
        bruttoGehalt: value,
      }));
    }
    if (handleType === "calculationId") {
      setContainerState((prevState) => ({
        ...prevState,
        calculationId: value,
      }));
    }

    if (handleType === "safeCalculationId") {
      setContainerState((prevState) => ({
        ...prevState,
        safeCalculationId: value,
      }));
    }
    if (handleType === "user") {
      setContainerState((prevState) => ({
        ...prevState,
        user: value,
      }));
    }
    if (handleType === "storedRows") {
      setContainerState((prevState) => ({
        ...prevState,
        storedRows: value,
      }));
    }
    if (handleType === "speicherTitel") {
      setContainerState((prevState) => ({
        ...prevState,
        speicherTitel: value,
      }));
    }
    if (handleType === "document") {
      setContainerState((prevState) => ({
        ...prevState,
        document: value,
      }));
    }
    if (handleType === "categories") {
      setContainerState((prevState) => ({
        ...prevState,
        categories: value,
      }));
    }
    if (handleType === "inputValues") {
      setContainerState((prevState) => ({
        ...prevState,
        inputValues: value,
      }));
    }
    if (handleType === "sums") {
      setContainerState((prevState) => ({
        ...prevState,
        sums: value,
      }));
    }
  }

  const bruttoNettoCtxValues = {
    containerState,
    handleContainerState,
  };

  return (
    <MonatsPtkContext.Provider value={bruttoNettoCtxValues}>
      <div className="bnr_container">
        <img src={BnrLogo} className="monatsPtkBNRLogo" alt="irgendwas"></img>
        <MonatsPtkSetup />
        {/* <h1>Rechen-Apps</h1>
        <div className="nk-lohnzettel">
          <img className="bnrLogo" src={BnrLogo} alt="logo"></img>
          <BruttoNettoRSetup />
        </div> */}
      </div>
    </MonatsPtkContext.Provider>
  );
}
