import { createContext } from "react";
const BruttoNettoContext = createContext({
  bruttoGehalt: 2000,
  abzugsBetrag: 100,
  steuerAbzug: 12,
  sVsgSatz: 13,
  sVsgBeitrag: 4,
  steuer: 3,
  nettoGehalt: 3333,
});

export default BruttoNettoContext; // making it available outside of its file
