import { createContext } from "react";
const ContainerContext = createContext({
  users: "",
  anmerkungen: "",
  category: "",
  assignedUsers: "",
  safeCalculationId: "",
  calculationId: "",

  user: "",
  storedRows: "",
  speicherTitel: "",
  document: "",
});

export default ContainerContext; // making it available outside of its file
