import React, { useRef } from "react";
import { useEffect, useContext } from "react";
import MonatatsPktContext from "../a_container/MonatsPtkContext";

export default function Ergebnisse() {
  const { containerState, handleContainerState } =
    useContext(MonatatsPktContext);
  const safeHandleContainerState = useRef(handleContainerState);
  const inputValues = containerState.inputValues;
  useEffect(() => {
    const calculateSums = () => {
      let sum2 = 0;
      let sum3 = 0;
      let sum4 = 0;

      for (let i = 1; i <= 31; i++) {
        const id = i.toString().padStart(2, "0");
        const value2 = parseFloat(inputValues[`mp${id}_2`]) || 0;
        const value3 = parseFloat(inputValues[`mp${id}_3`]) || 0;
        const value4 = parseFloat(inputValues[`mp${id}_4`]) || 0;

        sum2 += value2;
        sum3 += value3;
        sum4 += value4;
      }

      const sums = {
        sum2: sum2,
        sum3: sum3,
        sum4: sum4,
      };

      safeHandleContainerState.current("sums", sums);
    };

    calculateSums();
  }, [inputValues, safeHandleContainerState]);

  return (
    <div className="monatsptk_row  ">
      <div className="monatsptk_date"> </div>
      <div className="monatsptk_werte">∑ {containerState.sums.sum2}</div>
      <div className="monatsptk_werte">∑ {containerState.sums.sum3}</div>
      <div className="monatsptk_werte">∑ {containerState.sums.sum4}</div>
      <div className="monatsptk_note"> </div>
    </div>
  );
}
