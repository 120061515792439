import React, { useRef } from "react";
import { useEffect, useContext } from "react";
import BruttoNettoContext from "../a_container/BruttoNettoContext";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
export default function ErgJaHo() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const nettoGehalt = containerState.nettoGehalt;
  const safehandleContainerState = useRef(handleContainerState);
  useEffect(() => {
    console.log("useEffect running");

    const abgabenBerechnen = () => {
      let ergJaHoNetto = (
        containerState.nettoGehalt * 12 +
        parseFloat(containerState.nettoSZ13) +
        parseFloat(containerState.nettoSZ14)
      ).toFixed(2);
      safehandleContainerState.current("ergJaHoNetto", ergJaHoNetto);

      let ergJaHoBrutto =
        containerState.ergDGgesamt * 14 + containerState.bruttoGehalt * 14;

      safehandleContainerState.current("ergJaHoBrutto", ergJaHoBrutto);

      //   safehandleContainerState.current("steuer", gesamtSteuer.toFixed(2));
      //   safehandleContainerState.current("sVsgBeitrag", sVsgBeitrag.toFixed(2));
      //   safehandleContainerState.current("sVsgSatz", sVsgSatz.toFixed(2));
      //   safehandleContainerState.current("nettoGehalt", nettoGehalt.toFixed(2));
    };

    abgabenBerechnen();
  }, [nettoGehalt, safehandleContainerState]);
  return (
    <div style={{ "margin-top": "3.36rem" }}>
      <p className="bnr_lead">
        <b>Jahreshochrechnung</b>
      </p>
      <div className="bnr_net-pay bnr_trc2 bnr_result">
        DN netto{" "}
        <span className="bnr_tooltip" data-tooltip-id="tooltip14">
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.ergJaHoNetto.toLocaleString("de-DE", {
            maximumFractionDigits: 2,
          })}
          €
        </span>
      </div>
      <div className="bnr_net-cost bnr_trc1 bnr_result">
        DG brutto{" "}
        <span className="bnr_tooltip" data-tooltip-id="tooltip15">
          ℹ️
        </span>
        :
        <span className="bnr_help_float_right">
          {containerState.ergJaHoBrutto.toLocaleString("de-DE", {
            maximumFractionDigits: 2,
          })}
          €
        </span>
      </div>
      {/* <ReactTooltip id="tooltip14" place="bottom" variant="info">
        <p>12 mal lfd. Monatslohn + SZ, jeweils netto.</p>
      </ReactTooltip>
      <ReactTooltip id="tooltip15" place="bottom" variant="info">
        <p>
          <p>14 Monatslöhne + 14 Zuschläge. </p>
          <p>Zuschläge mit KF-Rabatt.</p>
          <p>
            Bei einer erhöhten mtl. Lohnsumme (mehrerer Mitarbeiter) entfällt
            der KF-Rabatt.
          </p>
        </p>
      </ReactTooltip> */}
    </div>
  );
}
