// Large Headlines Generator
// ASCII Art Blocks
// http://patorjk.com/software/taag/#p=display&f=Graffiti&t=Page%20Section
// 🔖Vz.:
// 1🟥 basic imports and state
// 2🟥 useEffect and handleSubmit
// 3🟥 form calculation
// 4🟥 returned html
// 🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
//_               _        _                            _                         _       _        _
//| |             (_)      (_)                          | |                       | |     | |      | |
//| |__   __ _ ___ _  ___   _ _ __ ___  _ __   ___  _ __| |_ ___    __ _ _ __   __| |  ___| |_ __ _| |_ ___
//| '_ \ / _` / __| |/ __| | | '_ ` _ \| '_ \ / _ \| '__| __/ __|  / _` | '_ \ / _` | / __| __/ _` | __/ _ \
//| |_) | (_| \__ \ | (__  | | | | | | | |_) | (_) | |  | |_\__ \ | (_| | | | | (_| | \__ \ || (_| | ||  __/
//|_.__/ \__,_|___/_|\___| |_|_| |_| |_| .__/ \___/|_|   \__|___/  \__,_|_| |_|\__,_| |___/\__\__,_|\__\___|
//  🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
import React, { useState, useEffect, useRef } from "react";
import "./NkLohnzettel_laknö23.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useCollection } from "../../../hooks/useCollection";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useFirestore } from "../../../hooks/useFirestore";
import { useDocument } from "../../../hooks/useDocument";
// import useCalculationUpdate from "../../../hooks/useCalculationUpdate";

import { useParams } from "react-router-dom";

const NkLohnzettelLaknö23 = () => {
  let { calculationId } = useParams();
  if (calculationId) {
    calculationId = calculationId.slice(1);
  }
  const safeCalculationId = useRef(calculationId).current;

  // const { calculation, loading, error, updateCalculation } =
  //   useCalculationUpdate(calculationId);
  const [users, setUsers] = useState([]);

  const [speicherTitel, setSpeicherTitel] = useState("");
  console.log(speicherTitel);
  const [anmerkungen, setAnmerkungen] = useState("");
  const [category, setCategory] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  // const [filteredUsers, setFilteredUsers] = useState([]);
  const [formError, setFormError] = useState(null);
  const [update, setUpdate] = useState(false);
  console.log(speicherTitel);
  const history = useHistory();
  const { document: loadedDocument, error } = useDocument(
    "calculations",
    safeCalculationId
  );
  const { addDocument: addNewDocument, response: useFirestoreResponse } =
    useFirestore("calculations");
  const { user } = useAuthContext();
  const { documents } = useCollection("users");
  const { updateDocument, response: updateResponse } =
    useFirestore("calculations");
  // 🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  //                _____ __  __                   _   _                     _ _      _____       _
  //               |  ___/ _|/ _|                 | | | |                   | | |    /  ___|     | |
  // _   _ ___  ___| |__| |_| |_    __ _ _ __   __| | | |__   __ _ _ __   __| | | ___\ `--. _   _| |__
  //| | | / __|/ _ \  __|  _|  _|  / _` | '_ \ / _` | | '_ \ / _` | '_ \ / _` | |/ _ \`--. \ | | | '_ \
  //| |_| \__ \  __/ |__| | | |_  | (_| | | | | (_| | | | | | (_| | | | | (_| | |  __/\__/ / |_| | |_) |
  // \__,_|___/\___\____/_| |_(_)  \__,_|_| |_|\__,_| |_| |_|\__,_|_| |_|\__,_|_|\___\____/ \__,_|_.__(_)

  //  🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  useEffect(() => {
    if (documents) {
      setUsers(
        documents.map((user) => ({
          value: { displayName: user.displayName, id: user.id },
          label: user.displayName,
        }))
      );
      console.log(users);
    }
  }, [documents]);
  useEffect(() => {
    if (users && safeCalculationId && loadedDocument && !update) {
      console.log(loadedDocument);
      // alert("is NO update");

      setSpeicherTitel(loadedDocument.speicherTitel);
      setAnmerkungen(loadedDocument.anmerkungen);
      setCategory(loadedDocument.category);
      setAssignedUsers(loadedDocument.assignedUsersList);
      setMonatlichesEinkommen(loadedDocument.monatlichesEinkommen);
      setAbzugsbetrag(loadedDocument.abzugsbetrag);
      setSteuerabzug(loadedDocument.steuerabzug);
      // alert(loadedDocument.steuerabzug);
      // setAssignedUsers(
      //   users.filter((user) => {
      //     // Check if the user's id is present in assignedUsers array
      //     return loadedDocument.assignedUsersList.some(
      //       (assignedUser) => assignedUser.id === user.value.id
      //     );
      //   })
      // );
    }
  }, [loadedDocument, safeCalculationId, update]);

  const goBack = () => {
    history.goBack();
  };

  const categories = [
    { value: "Lohnvg.", label: "Lohnvg." },
    { value: "Beiträge", label: "Beiträge" },
    { value: "Steuern", label: "Steuern" },
    { value: "Sonstiges", label: "Sonstiges" },
  ];

  const handleSubmit = async (e, isUpdate) => {
    let addedDocumentId = "";
    // alert(isUpdate);
    e.preventDefault();
    setFormError(null);
    setUpdate(isUpdate);

    if (!category) {
      setFormError("Bitte eine Kategorie wählen.");
      return;
    }

    if (assignedUsers.length < 1) {
      setFormError("Bitte mindestens 1 Teilnehmer zuweisen.");
      return;
    }
    const assignedUsersList = assignedUsers;

    // const assignedUsersList = assignedUsers.map((u) => ({
    //   displayName: u.value.displayName,
    //   id: u.value.id,
    // }));

    const createdBy = {
      displayName: user.displayName,
      id: user.uid,
    };

    const calcSummary = {
      a01_Vorlage: "Landarbeiter NÖ, Stand 1.1.2023",
      a02_Monatslohn: monatlichesEinkommen,
      a03_Abzugsbetrag: abzugsbetrag,
      a04_Absetzbetrag: steuerabzug,
      a05_Sozialversicherungsbeitrag: sozialversicherungsbeitrag,
      a06_Sozialversicherungssatz: sozialversicherungssatz,
      a07_Steuer: steuer,
      a08_Nettolohn: nettoGehalt,
    };

    const calculation = {
      speicherTitel: speicherTitel,
      bearbeitungsPfad: "/neue_kalkulation/nk_lohnzettel_laknö23",
      anmerkungen: anmerkungen,
      assignedUsersList: assignedUsersList,
      createdBy: createdBy,
      category: category,
      calcData: calcSummary,
      monatlichesEinkommen: monatlichesEinkommen,
      abzugsbetrag: abzugsbetrag,
      steuerabzug: steuerabzug,
      comments: [],
    };
    console.log(calculation);
    if (isUpdate) {
      // alert(calculation.calcData[0].a01_Vorlage);
      // alert("is update");

      try {
        await updateDocument(safeCalculationId, {
          speicherTitel: speicherTitel,
          anmerkungen: anmerkungen,
          assignedUsersList: assignedUsersList,
          createdBy: createdBy,
          category: category,
          calcData: calcSummary,
          monatlichesEinkommen: monatlichesEinkommen,
          abzugsbetrag: abzugsbetrag,
          steuerabzug: steuerabzug,
        });

        // Handle successful update
      } catch (error) {
        setFormError("update fehlgeschlagen");
      }
    } else {
      addedDocumentId = await addNewDocument(calculation);
    }

    if (isUpdate && !updateResponse.error) {
      console.log(updateResponse);
      history.push(`/calculations/${calculationId}`);
    }
    if (!isUpdate && !useFirestoreResponse.error) {
      console.log(useFirestoreResponse);
      history.push(`/calculations/${addedDocumentId}`);
    }
  };
  // 🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  //   __                                 _            _       _   _
  //  / _|                               | |          | |     | | (_)
  // | |_ ___  _ __ _ __ ___     ___ __ _| | ___ _   _| | __ _| |_ _  ___  _ __
  // |  _/ _ \| '__| '_ ` _ \   / __/ _` | |/ __| | | | |/ _` | __| |/ _ \| '_ \
  // | || (_) | |  | | | | | | | (_| (_| | | (__| |_| | | (_| | |_| | (_) | | | |
  // |_| \___/|_|  |_| |_| |_|  \___\__,_|_|\___|\__,_|_|\__,_|\__|_|\___/|_| |_|

  //  🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥

  const [monatlichesEinkommen, setMonatlichesEinkommen] = useState(
    (1708.74).toFixed(2)
  );
  const [abzugsbetrag, setAbzugsbetrag] = useState((132 / 12).toFixed(2));
  const [steuerabzug, setSteuerabzug] = useState((421 / 12).toFixed(2));
  const [steuer, setSteuer] = useState("");
  const [sozialversicherungsbeitrag, setSozialversicherungsbeitrag] =
    useState("");
  const [sozialversicherungssatz, setSozialversicherungssatz] = useState("");
  const [nettoGehalt, setNettoGehalt] = useState("");

  // useEffect(() => {
  //   if (loadedDocument) {

  // Update other form fields with the corresponding calculation properties
  //   }
  // }, [loadedDocument]);

  useEffect(() => {
    const steuerBerechnen = () => {
      const einkommenSteuerKlassen = [
        { min: 0, max: 11693 / 12, satz: 0 },
        { min: 11693.01 / 12, max: 19134 / 12, satz: 0.2 },
        { min: 19134.01 / 12, max: 32076 / 12, satz: 0.3 },
        { min: 32076.01 / 12, max: 62082 / 12, satz: 0.41 },
        { min: 62082.01 / 12, max: 93123 / 12, satz: 0.48 },
        { min: 93123.01 / 12, max: 1000000 / 12, satz: 0.5 },
        { min: 1000000.01 / 12, max: Infinity, satz: 0.55 },
      ];

      const sozialversicherungsKlassen = [
        { min: 0, max: 500.91, satz: 0 },
        { min: 500.92, max: 1885, satz: 0.1487 },
        { min: 1885.01, max: 2056, satz: 0.1587 },
        { min: 2056.01, max: 2228, satz: 0.1687 },
        { min: 2228.91, max: 5850, satz: 0.1787 },
      ];

      let sozialversicherungsBeitrag = 0;
      let sozialversicherungsSatz = 0;

      for (let i = 0; i < sozialversicherungsKlassen.length; i++) {
        const klasse = sozialversicherungsKlassen[i];

        if (
          monatlichesEinkommen >= klasse.min &&
          monatlichesEinkommen <= klasse.max
        ) {
          sozialversicherungsBeitrag = klasse.satz * monatlichesEinkommen;
          sozialversicherungsSatz = klasse.satz * 100;
          break;
        } else if (monatlichesEinkommen > klasse.max) {
          sozialversicherungsBeitrag = klasse.satz * klasse.max;
          sozialversicherungsSatz = klasse.satz * 100;
        }
      }

      const einkommenNachAbzug =
        monatlichesEinkommen - abzugsbetrag - sozialversicherungsBeitrag;
      const berechneteSteuerVorAbzug = einkommenSteuerBerechnen(
        einkommenNachAbzug,
        einkommenSteuerKlassen
      );
      const gesamtSteuerVorAbzug = berechneteSteuerVorAbzug;
      let gesamtSteuer = gesamtSteuerVorAbzug - steuerabzug;
      gesamtSteuer = Math.max(gesamtSteuer, 0);

      const nettoGehalt =
        monatlichesEinkommen - gesamtSteuer - sozialversicherungsBeitrag;

      setSteuer(gesamtSteuer.toFixed(2));
      setSozialversicherungsbeitrag(sozialversicherungsBeitrag.toFixed(2));
      setSozialversicherungssatz(sozialversicherungsSatz.toFixed(2));
      setNettoGehalt(nettoGehalt.toFixed(2));
    };

    const einkommenSteuerBerechnen = (einkommen, klassen) => {
      let berechneteSteuer = 0;

      for (let i = 0; i < klassen.length; i++) {
        const klasse = klassen[i];

        if (einkommen >= klasse.min) {
          const zuVersteuernderBetrag = Math.min(
            einkommen - klasse.min,
            klasse.max - klasse.min
          );
          berechneteSteuer += zuVersteuernderBetrag * klasse.satz;
        }
      }

      return berechneteSteuer;
    };

    steuerBerechnen();
  }, [
    monatlichesEinkommen,
    abzugsbetrag,
    steuerabzug,

    // users,
  ]);

  // 🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥
  //          _                             _   _     _             _
  //         | |                           | | | |   | |           | |
  // _ __ ___| |_ _   _ _ __ _ __   ___  __| | | |__ | |_ _ __ ___ | |
  //| '__/ _ \ __| | | | '__| '_ \ / _ \/ _` | | '_ \| __| '_ ` _ \| |
  //| | |  __/ |_| |_| | |  | | | |  __/ (_| | | | | | |_| | | | | | |
  //|_|  \___|\__|\__,_|_|  |_| |_|\___|\__,_| |_| |_|\__|_| |_| |_|_|

  //  🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥

  const popoverInhalt = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p>132/12 Werbungskostenpauschale</p>
        <p>421/12 Verkehrsabsetzbetrag</p>
        <p>Abzüge für Transport & Familie prüfen!</p>
        <p>mit 0.75% LAK statt 0.5% AK</p>
        <p>keine Wohnbauförderung für Landarbeiter in NÖ</p>
        <p>kein DZ</p>
        <p>SV-Reduktion bei Lehrlingen & Personen über 60 prüfen!</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <div id="link_1" className="CommunityRL_Fries">
        <div className="fries_container">
          <span className="FriesLabel">Lohnvg.</span>
          <img src="/Neue_Kalkulation/lohnverrechnung.png" alt="clr1"></img>
        </div>
      </div>
      <nav className="vz_backlink">
        <ul>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto"> ◀ </span>
              <span className="Klammer_rechts">】</span>
            </li>

            <span onClick={goBack}>Übersicht </span>
          </li>
        </ul>
      </nav>
      <div className="nk-lohnzettel">
        <h2 className="title">
          <OverlayTrigger placement="right" overlay={popoverInhalt}>
            <span>Brutto-Netto-Rechner ℹ️</span>
          </OverlayTrigger>
        </h2>
        <p>Landarbeiter NÖ, Stand 1.1.2023</p>

        <div className="form-field">
          <label htmlFor="monthlyIncome">Monatslohn: </label>
          <input
            type="number"
            id="monthlyIncome"
            value={monatlichesEinkommen}
            onChange={(event) =>
              setMonatlichesEinkommen(Number(event.target.value))
            }
          />
        </div>
        <div className="form-field">
          <label htmlFor="deductionAmount">Abzugsbetrag: </label>
          <input
            type="number"
            id="deductionAmount"
            value={abzugsbetrag}
            onChange={(event) => setAbzugsbetrag(Number(event.target.value))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="deductionTax">Absetzbetrag: </label>
          <input
            type="number"
            id="deductionTax"
            value={steuerabzug}
            onChange={(event) => setSteuerabzug(Number(event.target.value))}
          />
        </div>

        {sozialversicherungsbeitrag && (
          <p className="result">
            Sozialversicherungsbeitrag: {sozialversicherungsbeitrag}€
          </p>
        )}
        {sozialversicherungssatz && (
          <p className="result">
            Sozialversicherungssatz: {sozialversicherungssatz}%
          </p>
        )}
        {steuer && <p className="result">Steuer: {steuer}€</p>}
        {nettoGehalt && (
          <p className="result net-pay">Nettolohn: {nettoGehalt}€</p>
        )}
      </div>
      <label className="bruttoNettoAnmkg">
        <span>Anmerkungen:</span>
        <textarea
          value={anmerkungen}
          onChange={(e) => setAnmerkungen(e.target.value)}
        ></textarea>
      </label>

      <label className="bruttoNettoAnmkg speicherTitel">
        <span>Speicher-Titel:</span>
        <input
          required
          type="text"
          value={speicherTitel}
          onChange={(e) => setSpeicherTitel(e.target.value)}
        />
      </label>
      <label className="bruttoNettoAnmkg speicherTitel">
        <span>Kategorie:</span>
        <Select
          onChange={(option) => setCategory(option)}
          options={categories}
          placeholder="bitte auswählen"
          value={category}
        />
      </label>
      <label className="bruttoNettoAnmkg speicherTitel">
        <span>gespeichert für:</span>
        <Select
          // placeholder="bitte sich auch selbst hinzufügen..."
          isMulti
          value={assignedUsers}
          onChange={(option) => setAssignedUsers(option)}
          options={users}
        />
      </label>
      <span>{safeCalculationId}</span>
      {safeCalculationId ? (
        <button
          onClick={(e) => handleSubmit(e, true)}
          className="btn kalk_speichern"
        >
          Kalkulation updaten
        </button>
      ) : (
        <button
          onClick={(e) => handleSubmit(e, false)}
          className="btn kalk_speichern"
        >
          Kalkulation speichern
        </button>
      )}
      {formError && <p className="error">{formError}</p>}
    </div>
  );
};

export default NkLohnzettelLaknö23;
