import React, { useState, useEffect } from "react";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFirestore } from "../hooks/useFirestore";

export default function Bookmark({ document, collection, changeFilter }) {
  const { user } = useAuthContext();
  let buttonIcon1 = <FaBookmark />;
  let buttonIcon2 = <FaRegBookmark />;
  const [isBookmarked, setIsBookmarked] = useState(false);
  const { updateDocument, response } = useFirestore(collection);

  useEffect(() => {
    console.log(document);

    console.log(user.uid);
    setIsBookmarked(false);

    try {
      document.bookmarkedBy.length > 0 &&
        document.bookmarkedBy.forEach((u) => {
          if (u.id === user.uid) {
            setIsBookmarked(true);
          }
        });
    } catch {
      setIsBookmarked(false);
    }
  }, [document, changeFilter, user.uid]);

  let ButtonIcon = isBookmarked ? buttonIcon1 : buttonIcon2;
  async function buttonAction() {
    setIsBookmarked((isBookmarked) => !isBookmarked);
    let newBookmarkedBy = [];
    try {
      if (isBookmarked === true) {
        newBookmarkedBy = document.bookmarkedBy.filter(
          (obj) => obj.id !== user.uid
        );
      } else {
        newBookmarkedBy = [
          ...document.bookmarkedBy,
          { displayName: user.displayName, id: user.uid },
        ];
      }
    } catch {
      setIsBookmarked(false);
    }

    console.log(newBookmarkedBy);
    await updateDocument(document.id, {
      bookmarkedBy: newBookmarkedBy,
    });
  }
  let signal = "";
  if (isBookmarked === true) {
    signal = "bookm";
  }
  return (
    <>
      {isBookmarked}
      <button key={Math.random()} onClick={buttonAction}>
        {ButtonIcon}
      </button>
    </>
  );
}
