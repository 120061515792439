import React from "react";
import { useContext } from "react";
import BruttoNettoContext from "../a_container/BruttoNettoContext";

export default function Eingaben() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);

  return (
    <div>
      <p className="bnr_helper_margin1 bnr_lead">
        <label className="bnr_label" htmlFor="monthlyIncome">
          <b>
            Monatslohn lfd.
            <span className="bnr_tooltip" data-tooltip-id="tooltip2">
              ℹ️
            </span>
          </b>
        </label>
      </p>

      <div className="bnr_net-cost bnr_trc1 bnr_inputField">
        <input
          className="bnr_input"
          type="number"
          id="monthlyIncome"
          value={containerState.bruttoGehalt}
          onChange={(event) =>
            handleContainerState("bruttoGehalt", Number(event.target.value))
          }
        />
      </div>
      <div className="bnr_form-field">
        <label className="bnr_label" htmlFor="deductionAmount">
          <div className=" bnr_trc2 bnr_result">
            Abzugsbetrag
            <span className="bnr_tooltip" data-tooltip-id="tooltip3">
              ℹ️
            </span>
          </div>
        </label>
        <div className=" bnr_trc1 bnr_inputField">
          <input
            className="bnr_input"
            type="number"
            id="deductionAmount"
            value={containerState.abzugsBetrag}
            onChange={(event) =>
              handleContainerState("abzugsBetrag", Number(event.target.value))
            }
          />
        </div>
      </div>
      <div className="bnr_form-field">
        <div className=" bnr_trc2 bnr_result">
          <label className="bnr_label" htmlFor="deductionTax">
            Absetzbetrag
            <span className="bnr_tooltip" data-tooltip-id="tooltip4">
              ℹ️
            </span>
          </label>
        </div>
        <div className=" bnr_trc1 bnr_inputField">
          <input
            className="bnr_input"
            type="number"
            id="deductionTax"
            value={containerState.steuerAbzug}
            onChange={(event) =>
              handleContainerState("steuerAbzug", Number(event.target.value))
            }
          />
        </div>
      </div>
      {/* <ReactTooltip id="tooltip2" place="bottom" variant="info">
        <p>Mindestlohn gemäß Bäuerlichem KV beachten</p>
      </ReactTooltip>
      <ReactTooltip id="tooltip3" place="bottom" variant="info">
        <p>
          Abzugsbetrag im Sinn einer Minderung der Steuer-Bemessungsgrundlage.
        </p>
        <p>132/12 Werbungskostenpauschale ist anwendbar.</p>
        <p> Weitere Abzüge für Transport & Familie prüfen!</p>
      </ReactTooltip>

      <ReactTooltip id="tooltip4" place="bottom" variant="info">
        <p>Absetzbetrag im Sinn einer Minderung der Steuer-Schuld.</p>
        <p>421/12 Verkehrsabsetzbetrag ist anwendbar.</p>
        <p> Weitere Abzüge für Transport & Familie prüfen!</p>
      </ReactTooltip> */}
    </div>
  );
}
