import { useHistory } from "react-router-dom";

import { useFirestore } from "../../../../hooks/useFirestore";

export default function useDelete() {
  const { deleteDocument } = useFirestore("calculations");
  const history = useHistory();

  const handleDelete = (id) => {
    deleteDocument(id);
    history.push("/kalkulationen");
  };
  return { handleDelete };
}
