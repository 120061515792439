import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

// styles
import "./App.css";

// pages & components
import BootstrapComponent from "./components/BootstrapComponent";
import Dashboard from "./pages/konv_dashboard/Dashboard";
import WerIstDa from "./pages/wer_ist_da/wer_ist_da";
import Create from "./pages/konv_create/Create";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Project from "./pages/konv_einzelansicht/Project";
import KalkEinzelansicht from "./pages/kalk_einzelansicht/KalkEinzelansicht";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import OnlineUsers from "./components/OnlineUsers";
import ProDomo from "./pages/prodomo/prodomo";
import NeueKalkulation from "./pages/kalk_create/nk_anfang/Neue_Kalkulation";
import NKMonatsprotokoll from "./pages/kalk_create/nk_monatsprotokoll/nk_monatsprotokoll";
import NkBSVG from "./pages/kalk_create/nk_bsvg/NkBSVG";
import NkEst from "./pages/kalk_create/nk_est/NkEst";
import NkLohnzettelAk23 from "./pages/kalk_create/NkLohnzettelAK23/NkLohnzettel_ak23";
import NkLohnzettelLaknö23 from "./pages/kalk_create/NkLohnzettelLAKNÖ23/NkLohnzettel_laknö23";
import KalkDashboard from "./pages/kalk_dashboard/kalk_Dashboard";
import NkGsvg from "./pages/kalk_create/nk_gsvg/NkGsvg";
import BruttoNettoRContainer from "./pages/kalk_create/BruttoNettoRechnerKomponenten/a_container/BruttoNettoRContainer";
import MonatsPtkContainer from "./pages/kalk_create/MonatsPtkKomponenten/a_container/MonatsPtkContainer";
import ReportContainer from "./pages/report/a_container/ReportContainer";

function App() {
  const { authIsReady, user } = useAuthContext();

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <div>
          {authIsReady && (
            <div className="App">
              {user && <Sidebar />}
              <div className="container2">
                <Switch>
                  <Route exact path="/wer_ist_da">
                    {!user && <Redirect to="/login" />}
                    {user && <WerIstDa />}
                  </Route>
                  <Route exact path="/">
                    {!user && <Redirect to="/login" />}
                    {user && <Dashboard />}
                  </Route>
                  <Route path="/create">
                    {!user && <Redirect to="/login" />}
                    {user && <Create />}
                  </Route>
                  <Route path="/projects/:id">
                    {!user && <Redirect to="/login" />}
                    {user && <Project />}
                  </Route>
                  <Route path="/calculations/:id">
                    {!user && <Redirect to="/login" />}
                    {user && <KalkEinzelansicht />}
                  </Route>
                  <Route path="/login">
                    {user && <Redirect to="/" />}
                    {!user && <Login />}
                  </Route>
                  <Route path="/signup">
                    {user && user.displayName && <Redirect to="/" />}
                    {!user && <Signup />}
                  </Route>
                  <Route path="/prodomo">
                    {!user && <Redirect to="/login" />}
                    {user && <ProDomo />}
                  </Route>
                  <Route exact path="/neue_kalkulation">
                    {!user && <Redirect to="/login" />}
                    {user && <NeueKalkulation />}
                  </Route>
                  <Route exact path="/neue_kalkulation/nk_monatsprotokoll">
                    {!user && <Redirect to="/login" />}
                    {user && <NKMonatsprotokoll />}
                  </Route>

                  <Route path="/neue_kalkulation/nk_lohnzettel_laknö23:calculationId">
                    {!user && <Redirect to="/login" />}
                    {user && <BruttoNettoRContainer />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_monatsprotokoll_comp:calculationId">
                    {!user && <Redirect to="/login" />}
                    {user && <MonatsPtkContainer />}
                  </Route>

                  {/* <Route path="/neue_kalkulation/nk_monatsprotokoll:calculationId">
                    {!user && <Redirect to="/login" />}
                    {user && <NKMonatsprotokoll />}
                  </Route> */}
                  <Route path="/neue_kalkulation/nk_lohnzettel_laknö23">
                    {!user && <Redirect to="/login" />}
                    {user && <NkLohnzettelLaknö23 />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_lohnzettel_ak23">
                    {!user && <Redirect to="/login" />}
                    {user && <NkLohnzettelAk23 />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_bsvg">
                    {!user && <Redirect to="/login" />}
                    {user && <NkBSVG />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_gsvg">
                    {!user && <Redirect to="/login" />}
                    {user && <NkGsvg />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_est">
                    {!user && <Redirect to="/login" />}
                    {user && <NkEst />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_est">
                    {!user && <Redirect to="/login" />}
                    {user && <NkEst />}
                  </Route>
                  <Route path="/kalkulationen">
                    {!user && <Redirect to="/login" />}
                    {user && <KalkDashboard />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_lab_comp">
                    {!user && <Redirect to="/login" />}
                    {user && <BruttoNettoRContainer />}
                  </Route>
                  <Route path="/neue_kalkulation/nk_monatsprotokoll_comp">
                    {!user && <Redirect to="/login" />}
                    {user && <MonatsPtkContainer />}
                  </Route>
                  <Route path="/report">
                    {!user && <Redirect to="/login" />}
                    {user && <ReportContainer />}
                  </Route>
                </Switch>
                {user && <BootstrapComponent />}
              </div>
              {/* {user && <OnlineUsers />} */}
            </div>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
