import { useContext, React } from "react";
import Select from "react-select";
import useSubmit from "./useSubmit";
import useDelete from "./useDelete";
import BruttoNettoContext from "../a_container/BruttoNettoContext";

export default function BruttoNettoRSaveMenu() {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const { handleSubmit } = useSubmit();
  const { handleDelete } = useDelete();
  let paragraph = "";
  if (
    containerState.calculationId &&
    containerState.user.uid === containerState.createdBy.id
  ) {
    paragraph = (
      <>
        <button
          onClick={(e) => handleSubmit(e, true)}
          className="btn kalk_speichern"
        >
          Kalkulation updaten
        </button>
        <button
          onClick={(e) => handleDelete(containerState.calculationId)}
          className="btn kalk_speichern"
        >
          Kalkulation löschen
        </button>
      </>
    );
  } else if (!containerState.calculationId) {
    paragraph = (
      <button
        onClick={(e) => handleSubmit(e, false)}
        className="btn kalk_speichern"
      >
        Kalkulation speichern
      </button>
    );
  } else {
    paragraph = <></>;
  }

  return (
    <div>
      <label className="bruttoNettoAnmkg">
        <span>Anmerkungen:</span>
        <textarea
          value={containerState.anmerkungen}
          onChange={(e) => handleContainerState("anmerkungen", e.target.value)}
        ></textarea>
      </label>

      <label className="bruttoNettoAnmkg speicherTitel">
        <span>Speicher-Titel:</span>
        <input
          required
          type="text"
          value={containerState.speicherTitel}
          onChange={(e) =>
            handleContainerState("speicherTitel", e.target.value)
          }
        />
      </label>
      <label className="bruttoNettoAnmkg speicherTitel">
        <span>Kategorie:</span>
        <Select
          onChange={(option) => handleContainerState("category", option)}
          options={containerState.categories}
          placeholder="bitte auswählen"
          value={containerState.category}
        />
      </label>
      <label className="bruttoNettoAnmkg speicherTitel">
        <span>gespeichert für:</span>
        <Select
          // placeholder="bitte sich auch selbst hinzufügen..."
          isMulti
          value={containerState.assignedUsers}
          onChange={(option) => handleContainerState("assignedUsers", option)}
          options={containerState.users}
        />
      </label>
      <span>{containerState.safeCalculationId}</span>
      {paragraph}
      {containerState.formError && (
        <p className="error">{containerState.formError}</p>
      )}
    </div>
  );
}
