// styles
import "./Avatar.css";
import avatar_holy from "../assets/Avatar_Holy.png";

export default function Avatar({ src }) {
  return (
    <div className="avatar">
      <img src={avatar_holy} alt="user avatar" />
    </div>
  );
}
