import React, { useState, useEffect, useRef, useContext } from "react";
import "./NkLohnzettel_laknö23.css";
import { useCollection } from "../../../../hooks/useCollection";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useFirestore } from "../../../../hooks/useFirestore";
import { useDocument } from "../../../../hooks/useDocument";
import BruttoNettoContext from "../a_container/BruttoNettoContext";
import Eingaben from "../c_module/Eingaben";
import Ergebnisse from "../c_module/Ergebnisse";
import BruttoNettoRSaveMenu from "../c_module/BruttoNettoRSaveMenu";
import { useParams } from "react-router-dom";
import KalkCommentsUniversal from "../../../../components/KalkCommentsUniversal";
import ErgSz13 from "../c_module/ErgSz13";
import ErgSz14 from "../c_module/ErgSz14";
import ErgDGZ from "../c_module/ErgDGZ";
import ErgJaHo from "../c_module/ErgJaHo";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import BnrLogo from "../../../../assets/BnrLogo2.svg";

const BruttoNettoRSetup = () => {
  const { containerState, handleContainerState } =
    useContext(BruttoNettoContext);
  const { user } = useAuthContext();
  let { calculationId } = useParams();
  if (calculationId) {
    calculationId = calculationId.slice(1);
  }

  const safeCalculationId = useRef(calculationId).current;
  const [update, setUpdate] = useState(false);

  console.log("user", user);

  console.log("calculation id", calculationId);
  console.log("safe calculation id", calculationId);

  useEffect(() => {
    if (calculationId) {
      handleContainerState("calculationId", calculationId);
    }
  }, [calculationId]);

  // ->problem start
  const { document: loadedDocument, error } = useDocument(
    "calculations",
    safeCalculationId
  );
  console.log("loaded document", loadedDocument);
  const { documents } = useCollection("users");
  console.log("loaded users", documents);
  console.log("containerState", containerState);

  useEffect(() => {
    if (documents && user) {
      handleContainerState(
        "users",
        documents.map((user) => ({
          value: { displayName: user.displayName, id: user.id },
          label: user.displayName,
        }))
      );
      handleContainerState("user", user);
    }
  }, [documents]);

  useEffect(() => {
    if (loadedDocument && documents) {
      console.log("loadedDocument", loadedDocument);

      handleContainerState("loadedDocument", loadedDocument);

      handleContainerState("documents", documents);
      handleContainerState(
        "users",
        documents.map((user) => ({
          value: { displayName: user.displayName, id: user.id },
          label: user.displayName,
        }))
      );
    }
  }, [loadedDocument, documents]);
  // ->problem ende

  useEffect(() => {
    if (documents && calculationId && loadedDocument && !update) {
      console.log(containerState.loadedDocument);
      // alert("is NO update");

      handleContainerState(
        "speicherTitel",
        containerState.loadedDocument.speicherTitel
      );
      handleContainerState(
        "anmerkungen",
        containerState.loadedDocument.anmerkungen
      );
      handleContainerState("category", containerState.loadedDocument.category);
      handleContainerState(
        "assignedUsers",
        containerState.loadedDocument.assignedUsersList
      );
      console.log(containerState.loadedDocument.assignedUsersList);
      console.log(containerState.loadedDocument.monatlichesEinkommen);
      handleContainerState(
        "bruttoGehalt",
        containerState.loadedDocument.monatlichesEinkommen
      );
      handleContainerState(
        "abzugsbetrag",
        containerState.loadedDocument.abzugsbetrag
      );
      handleContainerState(
        "steuerabzug",
        containerState.loadedDocument.steuerabzug
      );
      handleContainerState(
        "createdBy",
        containerState.loadedDocument.createdBy
      );
    }
  }, [containerState.loadedDocument, calculationId, update]);

  return (
    <div>
      <div className="bnr_nk-lohnzettel">
        <div className="bnr_nk_header">
          <img className="bnr_bnrLogo" src={BnrLogo} alt="logo"></img>

          <h2 className="bnr_title">
            Brutto-Netto-Rechner
            <Tippy
              content={
                <p>
                  I SV-Reduktion bei Lehrlingen & Personen über 60 nicht
                  berücksichtig!
                </p>
              }
            >
              <span>ℹ️</span>
            </Tippy>
          </h2>
          <p style={{ color: "#058785" }}>Landarbeiter NÖ, Stand 1.1.2023</p>
        </div>
        <div className="bnr_nk_body">
          <div className="bnr_spalte1">
            <Eingaben />
            <Ergebnisse />
          </div>
          <div className="bnr_spalte2">
            <ErgSz13 />
            <ErgSz14 />
          </div>
          <div className="bnr_spalte3">
            <ErgDGZ />
            <ErgJaHo />
          </div>
        </div>
      </div>
      <BruttoNettoRSaveMenu></BruttoNettoRSaveMenu>
      {containerState.loadedDocument && (
        <KalkCommentsUniversal
          calculation={containerState.loadedDocument}
        ></KalkCommentsUniversal>
      )}
    </div>
  );
};

export default BruttoNettoRSetup;
