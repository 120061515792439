import React, { useState, useEffect } from "react";
import "./NkLohnzettel_ak23.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Link, useHistory } from "react-router-dom";

const NkLohnzettelAk23 = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack(); // Navigates back to the previous page
  };
  const [monatlichesEinkommen, setMonatlichesEinkommen] = useState(
    (1708.74).toFixed(2)
  );
  const [abzugsbetrag, setAbzugsbetrag] = useState((132 / 12).toFixed(2));
  const [steuerabzug, setSteuerabzug] = useState((421 / 12).toFixed(2));
  const [steuer, setSteuer] = useState("");
  const [sozialversicherungsbeitrag, setSozialversicherungsbeitrag] =
    useState("");
  const [sozialversicherungssatz, setSozialversicherungssatz] = useState("");
  const [nettoGehalt, setNettoGehalt] = useState("");

  useEffect(() => {
    const steuerBerechnen = () => {
      // Definiere die progressiven Steuerklassen und Steuersätze
      const einkommenSteuerKlassen = [
        { min: 0, max: 11693 / 12, satz: 0 },
        { min: 11693.01 / 12, max: 19134 / 12, satz: 0.2 },
        { min: 19134.01 / 12, max: 32076 / 12, satz: 0.3 },
        { min: 32076.01 / 12, max: 62082 / 12, satz: 0.41 },
        { min: 62082.01 / 12, max: 93123 / 12, satz: 0.48 },
        { min: 93123.01 / 12, max: 1000000 / 12, satz: 0.5 },
        { min: 1000000.01 / 12, max: Infinity, satz: 0.55 },
      ];
      // Sozialversicherungsbeitrag berechnen
      const sozialversicherungsKlassen = [
        { min: 0, max: 500.91, satz: 0 },
        { min: 500.92, max: 1885, satz: 0.1512 },
        { min: 1885.01, max: 2056, satz: 0.1612 },
        { min: 2056.01, max: 2228, satz: 0.1712 },
        { min: 2228.91, max: 5850, satz: 0.1812 },
      ];

      let sozialversicherungsBeitrag = 0;
      let sozialversicherungsSatz = 0;

      for (let i = 0; i < sozialversicherungsKlassen.length; i++) {
        const klasse = sozialversicherungsKlassen[i];

        if (
          monatlichesEinkommen >= klasse.min &&
          monatlichesEinkommen <= klasse.max
        ) {
          sozialversicherungsBeitrag = klasse.satz * monatlichesEinkommen;
          sozialversicherungsSatz = klasse.satz * 100;
          break; // Stoppe die Berechnung, sobald die passende Klasse gefunden wurde
        } else if (monatlichesEinkommen > klasse.max) {
          sozialversicherungsBeitrag = klasse.satz * klasse.max;
          sozialversicherungsSatz = klasse.satz * 100;
        }
      }

      // Abzüge anwenden
      const einkommenNachAbzug =
        monatlichesEinkommen - abzugsbetrag - sozialversicherungsBeitrag;
      const berechneteSteuerVorAbzug = einkommenSteuerBerechnen(
        einkommenNachAbzug,
        einkommenSteuerKlassen
      );
      console.log(berechneteSteuerVorAbzug);
      const gesamtSteuerVorAbzug = berechneteSteuerVorAbzug;
      let gesamtSteuer = gesamtSteuerVorAbzug - steuerabzug;
      gesamtSteuer = Math.max(gesamtSteuer, 0);
      const nettoGehalt =
        monatlichesEinkommen - gesamtSteuer - sozialversicherungsBeitrag;

      setSteuer(gesamtSteuer.toFixed(2));
      setSozialversicherungsbeitrag(sozialversicherungsBeitrag.toFixed(2));
      setSozialversicherungssatz(sozialversicherungsSatz.toFixed(2));
      setNettoGehalt(nettoGehalt.toFixed(2));
    };

    const einkommenSteuerBerechnen = (einkommen, klassen) => {
      let berechneteSteuer = 0;

      for (let i = 0; i < klassen.length; i++) {
        const klasse = klassen[i];

        if (einkommen >= klasse.min) {
          console.log(einkommen, klasse.min, klasse.max);
          const zuVersteuernderBetrag = Math.min(
            einkommen - klasse.min,
            klasse.max - klasse.min
          );
          berechneteSteuer += zuVersteuernderBetrag * klasse.satz;
        }
      }

      return berechneteSteuer;
    };

    steuerBerechnen();
  }, [monatlichesEinkommen, abzugsbetrag, steuerabzug]);

  const popoverInhalt = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p>132/12 Werbungskostenpauschale</p>
        <p>421/12 Verkehrsabsetzbetrag</p>
        <p>Abzüge für Transport & Familie prüfen!</p>
        <p>mit 0,5% AK statt 0,75% LAK </p>
        <p>mit Wohnbauförderung </p>
        <p>mit DZ</p>
        <p>SV-Reduktion bei Lehrlingen & Personen über 60 prüfen!</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <div id="link_1" className="CommunityRL_Fries">
        <div className="fries_container">
          <span className="FriesLabel">Lohnvg.</span>
          <img src="/Neue_Kalkulation/lohnverrechnung.png" alt="clr1"></img>
        </div>
      </div>
      <nav className="vz_backlink">
        <ul>
          <li className="pikto_cont_link">
            <li className="klein_pikto_container">
              <span className="Klammer_links">【</span>
              <span className="klein_pikto"> ◀ </span>
              <span className="Klammer_rechts">】</span>
            </li>

            <span onClick={goBack}>Übersicht </span>
          </li>
        </ul>
      </nav>
      <div className="nk-lohnzettel">
        <h2 className="title">
          <OverlayTrigger placement="right" overlay={popoverInhalt}>
            <span>Brutto-Netto-Rechner ℹ️</span>
          </OverlayTrigger>
        </h2>
        <p>Nicht-Landarbeiter, Stand 1.1.2023</p>

        <div className="form-field">
          <label htmlFor="monthlyIncome">Monatslohn: </label>
          <input
            type="number"
            id="monthlyIncome"
            value={monatlichesEinkommen}
            onChange={(event) =>
              setMonatlichesEinkommen(Number(event.target.value))
            }
          />
        </div>
        <div className="form-field">
          <label htmlFor="deductionAmount">Abzugsbetrag: </label>
          <input
            type="number"
            id="deductionAmount"
            value={abzugsbetrag}
            onChange={(event) => setAbzugsbetrag(Number(event.target.value))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="deductionTax">Steuerabzug: </label>
          <input
            type="number"
            id="deductionTax"
            value={steuerabzug}
            onChange={(event) => setSteuerabzug(Number(event.target.value))}
          />
        </div>

        {sozialversicherungsbeitrag && (
          <p className="result">
            Sozialversicherungsbeitrag: {sozialversicherungsbeitrag}€
          </p>
        )}
        {sozialversicherungssatz && (
          <p className="result">
            Sozialversicherungssatz: {sozialversicherungssatz}%
          </p>
        )}
        {steuer && <p className="result">Steuer: {steuer}€</p>}
        {nettoGehalt && (
          <p className="result net-pay">Nettolohn: {nettoGehalt}€</p>
        )}
      </div>
    </div>
  );
};

export default NkLohnzettelAk23;
